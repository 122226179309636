<template>
  <SlModal
    :id="id"
    :loading="!progress.stages"
    no-header
    @on-enter="handleClose"
    @created="onCreated"
    @show="onShow"
    @hide="onModalHide"
  >
    <div class="modal-content progress-modal">
      <SlLinearLoader
        v-for="stage in progress.stages"
        :key="stage.name"
        :label="stage.name"
        :value="getProgressValue(stage)"
        :width="getProgressWidth(stage)"
      />
    </div>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';
import { progressStates } from '@/config/report';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'ExportProgressModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.EXPORT_REPORT_PROGRESS,
      progress: {},
      subscriber: () => {},
      stageTypes: {
        NAMED: 'named',
        NUMERICAL: 'numerical'
      }
    };
  },
  methods: {
    getProgressWidth(stage) {
      if (stage.type === this.stageTypes.NAMED) {
        return this.getStagedProgressWidth(stage);
      }

      return this.getNumericalProgressWidth(stage);
    },
    getProgressValue(stage) {
      if (stage.type === this.stageTypes.NAMED) {
        return this.getStagedProgressValue(stage);
      }

      return this.getNumericalProgressValue(stage);
    },
    getStagedProgressWidth(progress) {
      if (progress.status === progressStates.FINISHED) {
        return '100%';
      }

      return '0%';
    },
    getStagedProgressValue(progress) {
      if (progress.status === progressStates.FINISHED) {
        return '100%';
      }

      return '0%';
    },
    getNumericalProgressWidth(progress) {
      const current = progress.current || 0;
      const total = progress.total || 100;

      return (100 * current / total).toFixed(2) + '%';
    },
    getNumericalProgressValue(progress) {
      const current = progress.current || 0;
      const total = progress.total || 100;

      return `${current} / ${total}`;
    },
    async onShow() {
      try {
        toggleUserInteraction(true);

        const { progress } = await this.$store.dispatch('operations/subscribe', {
          subscriber: this.subscriber,
          in_progress: ({ progress }) => {
            this.progress = this.formatProgress(progress);
          }
        });

        if (progress) {
          this.progress = this.formatProgress(progress);
        }

        this.$notify({
          type: 'success',
          title: this.$t('Main.Ui.acExportInventory'),
          text: this.$t('ExportToDatabase.Ok')
        });
      } catch (e) {
        const errorMessage = e?.message ?? this.$t('Error.DbInventoryExport');

        this.$notify({
          type: 'error',
          title: this.$t('Main.Ui.acExportInventory'),
          text: errorMessage,
          duration: -1
        });
      } finally {
        toggleUserInteraction(false);

        setTimeout(() => {
          this.hideModal(this.id);
        }, 200);
      }
    },
    formatProgress(progress) {
      return progress.stages ? progress : { stages: [progress] };
    },
    onModalHide() {
      this.progress = {};
    },
    handleClose() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/export-progress-modal.scss";
</style>
