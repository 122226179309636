<template>
  <PageWrapper
    :loading="isLoading"
    class="inventory"
    :class="{
      'inventory-pagination': isPaginationVisible
    }"
  >
    <template #modal>
      <CreateCollectionModal :id="modalsId.CREATE_COLLECTION" />
      <ReportFilterModal />
      <NotifyModal :id="modalsId.NOTIFY" />
      <ConfirmModal
        :id="modalsId.CONFIRM"
        width="290"
        without-cancel
      />
      <ExportProgressModal />
    </template>
    <template #toolbar>
      <component
        :is="toolbarComponent"
        v-if="isTableExist"
        :show-hide-items="colVisibilityItems"
        @on-search="fetchPage"
      />
    </template>
    <InventoryTable
      v-if="isTableExist"
      ref="table"
      class="inventory-table"
      :table="table"
      :start-index="startIndex"
      @table-update="(data) => updateTable({ ...data, searchQuery })"
      @selected-items-change="setSelectedRows"
    />
    <SlPagination
      v-if="isPaginationVisible"
      :page="queryPage"
      :total-count="totalRowsCount"
      :per-page="PAGE_SIZE"
      :selected="selectedRows"
      @change-page="getPage"
    />
    <template #loader>
      <InventorySkeleton />
    </template>
  </PageWrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { access } from '@/mixins/access';
import { pagination } from '@/mixins/pagination';
import { modal } from '@/mixins/modal';
import PageWrapper from '@/components/Shared/PageWrapper.vue';
import InventoryReportToolbar from '@/components/Report/Inventory/Toolbar.vue';
import ForecastReportToolbar from '@/components/Report/Forecast/Toolbar.vue';
import InventoryTable from '@/components/InventoryTable/InventoryTable.vue';
import CreateCollectionModal from '@/components/Modals/Collections/CreateCollectionModal.vue';
import NotifyModal from '@/components/Modals/Shared/NotifyModal.vue';
import ConfirmModal from '@/components/Modals/Shared/ConfirmModal.vue';
import ReportFilterModal from '@/components/Modals/Report/ReportFilterModal.vue';
import ExportProgressModal from '@/components/Modals/Report/ExportProgressModal.vue';
import SlPagination from '@/components/Shared/SlPagination.vue';
import InventorySkeleton from '@/components/Skeleton/InventorySkeleton.vue';
import modalsId from '@/config/shared/modalsId.config';
import { namespaceByRoute, PAGE_SIZE } from '@/config/report';
import { routeNames } from '@/config/router/router.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';

export default {
  name: 'Report',
  components: {
    PageWrapper,
    InventoryReportToolbar,
    ForecastReportToolbar,
    InventoryTable,
    CreateCollectionModal,
    ReportFilterModal,
    NotifyModal,
    ConfirmModal,
    SlPagination,
    InventorySkeleton,
    ExportProgressModal
  },
  mixins: [access, pagination, modal],
  props: {
    link: Boolean
  },
  data() {
    return {
      modalsId,
      PAGE_SIZE,
      isExporting: false,
      isLoading: true,
      selectedRows: null
    };
  },
  provide() {
    return {
      handleExportToDatabase: this.handleExportToDatabase
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    toolbarComponent() {
      if (this.isForecastReport) {
        return 'ForecastReportToolbar';
      }

      return 'InventoryReportToolbar';
    },
    colVisibilityItems() {
      return this.$store.state[this.reportNamespace]?.colVisibilityItems;
    },
    table() {
      return this.$store.getters[`${this.reportNamespace}/reportTable`];
    },
    totalRowsCount() {
      return +this.table?.N_rows || null;
    },
    isPaginationVisible() {
      return this.totalRowsCount > PAGE_SIZE;
    },
    isTableExist() {
      return this.table?.body && this.reportNamespace;
    },
    isForecastReport() {
      return this.$sl_routeName === routeNames.REPORTS;
    },
    storedIsLink() {
      return this.$store.state[this.reportNamespace]?.isLink;
    },
    searchQuery() {
      return this.$route.query.search;
    }
  },
  watch: {
    $sl_routeName(newName) {
      this.resetIsLink();

      if ([routeNames.INVENTORY, routeNames.INTERSTORE].includes(newName)) {
        this.clearOrders();
        this.fetchPage();
      }
    },
    searchQuery() {
      this.resetIsLink();
      this.resetSelectedRows();
    },
    storedIsLink() {
      this.resetSelectedRows();
    }
  },
  async mounted() {
    try {
      await this.setIsLink(this.link);
      await sendRequests(this.$sl_routeName);
    } finally {
      this.$nextTick(() => {
        this.isLoading = false;
      });
    }
  },
  methods: {
    ...mapActions({
      clearOrders: 'inventoryReport/plannedOrders/clearOrders',
      updateTable: 'inventoryReport/updateTable'
    }),
    resetSelectedRows() {
      return this.$store.dispatch(`${this.reportNamespace}/setSelectedRows`, []);
    },
    setIsLink(isLink) {
      return this.$store.dispatch(`${this.reportNamespace}/setIsLink`, isLink);
    },
    resetIsLink() {
      if (this.storedIsLink) {
        this.setIsLink(false);
      }
    },
    fetchPage() {
      return this.$store.dispatch(`${this.reportNamespace}/fetchPage`);
    },
    async getPage(page) {
      this.resetSelectedRows();
      await this.updatePageRoute(page);

      this.fetchPage();
      this.$refs.table?.resetSelection();
      document.querySelector('.inventory-table__scroller').scrollTop = 0;
    },
    exportTableToDatabase() {
      return this.$store.dispatch(`${this.reportNamespace}/exportTableToDatabase`);
    },
    handleExportToDatabase() {
      if (this.$sl_routeName === routeNames.INVENTORY) {
        this.exportToDatabaseWithProgress();
      } else {
        this.exportToDatabaseSimple();
      }
    },
    async exportToDatabaseSimple() {
      try {
        toggleUserInteraction(true);
        this.isExporting = true;

        await this.$store.dispatch('operations/subscribe', {
          subscriber: this.exportTableToDatabase
        });

        this.$notify({
          type: 'success',
          title: this.$t('Main.Ui.acExportInventory'),
          text: this.$t('ExportToDatabase.Ok')
        });
      } catch (e) {
        const errorMessage = e?.message?._fallback ?? e?.message ?? this.$t('Error.DbInventoryExport');

        this.$notify({
          type: 'error',
          title: this.$t('Main.Ui.acExportInventory'),
          text: errorMessage,
          duration: -1
        });
      } finally {
        toggleUserInteraction(false);
        this.isExporting = false;
      }
    },
    exportToDatabaseWithProgress() {
      this.showModal(modalsId.EXPORT_REPORT_PROGRESS, {
        subscriber: this.exportTableToDatabase
      });
    },
    setSelectedRows(val) {
      this.selectedRows = Array.isArray(val) ? val.length : Object.keys(val).length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inventory-table/tab.scss";
</style>
