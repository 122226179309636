// required events for item select (select current clicked item only)
// @mousedown.native.exact="() => startItemsSelect(item)"

// optional events for item select
// click-hold & move select range between clicked element and last item cursor over
// @mouseenter.native.exact="() => moveItemsSelect(item)"

// ctrl + click add current clicked item to existed selected list
// @click.ctrl.native.exact="() => currentItemSelect(item)"

// shift + click select range between two selected items
// @click.shift.native.exact="() => rangeItemsSelect(item)"

export const itemSelect = {
  data() {
    return {
      selectedItems: [],
      oldSelectedItems: [],
      startSelectItem: null,
      endSelectItem: null
    };
  },
  computed: {
    lastSelectedItem() {
      if (!this.selectedItems.length) {
        return null;
      }

      return this.selectedItems[this.selectedItems.length - 1];
    }
  },
  watch: {
    selectedItems(_, oldValue) {
      this.oldSelectedItems = oldValue;
    }
  },
  methods: {
    _getPoint(a, b, direction) {
      if (direction === 'start') {
        return a < b ? a : b;
      }

      if (direction === 'end') {
        return a > b ? a : b;
      }

      return null;
    },
    _getCount(a, b) {
      return a - b + 1;
    },
    _fillSelectedRows(count, start) {
      // todo optimize handle selected items
      return Array(count).fill(0).map((i, idx) => start + idx);
    },
    currentItemSelect(item) {
      if (!this.selectedItems.includes(item) && item !== null) {
        this.selectedItems.push(item);

        return this.selectedItems;
      }
    },
    rangeItemsSelect(item) {
      if (this.selectedItems.length) {
        const first = this.selectedItems[0];
        const start = this._getPoint(item, first, 'start');
        const end = this._getPoint(item, first, 'end');
        const count = this._getCount(end, start);

        this.selectedItems = this._fillSelectedRows(count, start);
      } else {
        this.selectedItems = [item];
      }

      return this.selectedItems;
    },
    startItemsSelect(item) {
      if (item === this.selectedItems[0]) {
        return this.startSelectItem;
      }

      document.addEventListener('mouseup', this.endItemsSelect);

      this.selectedItems = [item];
      this.startSelectItem = item;

      return this.selectedItems;
    },
    moveItemsSelect(item) {
      if (this.startSelectItem !== null) {
        const start = this._getPoint(item, this.startSelectItem, 'start');
        const end = this._getPoint(item, this.startSelectItem, 'end');
        const count = this._getCount(end, start);

        this.selectedItems = this._fillSelectedRows(count, start);
      }

      return this.selectedItems;
    },
    endItemsSelect() {
      this.startSelectItem = null;

      document.removeEventListener('mouseup', this.endItemsSelect);
    },
    setSelectedRow(item) {
      this.selectedItems = [item];
    },
    setSelectedItems(items) {
      this.selectedItems = items;
    },
    selectAllRows(rowsNumber) {
      this.selectedItems = this._fillSelectedRows(rowsNumber, 0);
    },
    resetSelection() {
      this.selectedItems = [];
      this.oldSelectedItems = [];
    }
  }
};