<template>
  <ToolbarWrapper>
    <template #filters>
      <FilterTabs />
    </template>
    <template #search>
      <Search
        ref="search"
        v-model="search"
        data-test-id="report-search-txt"
        @submit-search="searchItems"
      />
    </template>
    <ToolbarMenuButton
      v-if="!isAllItemsTab"
      :label="$t('Main.Ui.acEditInventoryFilter')"
      :items="editFilterItems"
      :click-handler="handleFilterEdit"
      data-toolbar-item
      @[editFilterKeys.EDIT]="handleFilterEdit"
      @[editFilterKeys.DUP]="duplicateFilter"
    >
      <template #icon>
        <icon
          data="@icon/filter.svg"
          class="size-14"
        />
      </template>
    </ToolbarMenuButton>
    <template>
      <slot />
    </template>
    <ColVisibilityMenu
      :items="showHideItems"
      @change-col-visibility="updateVisibleCols"
    />
    <span
      v-for="(label, i) of tableStatsLabels"
      :key="i"
      class="toolbar-label"
      data-toolbar-item
    >
      {{ label }}
    </span>
  </ToolbarWrapper>
</template>

<script>
import { mapActions } from 'vuex';
import searchMixin from '@/mixins/search.mixin';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import ToolbarWrapper from '@/components/Toolbar/ToolbarWrapper.vue';
import Search from '@/components/Toolbar/Search';
import FilterTabs from '@/components/Toolbar/FilterTabs.vue';
import ToolbarMenuButton from '@/components/Toolbar/ToolbarMenuButton';
import ColVisibilityMenu from '@/components/Toolbar/ColVisibilityMenu.vue';
import modalsId from '@/config/shared/modalsId.config';
import { editFilterKeys, exportTableKeys, namespaceByRoute } from '@/config/report';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getColValueWithEntry } from '@/helpers/locale/getColValueWithEntry.js';

export default {
  name: 'ReportToolbar',
  components: {
    ToolbarWrapper,
    FilterTabs,
    Search,
    ToolbarMenuButton,
    ColVisibilityMenu
  },
  mixins: [modal, searchMixin, access],
  props: {
    showHideItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      exportTableKeys,
      editFilterKeys,
      editFilterItems: [
        {
          title: this.$t('Main.Ui.acEditInventoryFilter'),
          value: editFilterKeys.EDIT
        },
        {
          title: this.$t('Main.Ui.acDuplicateInventoryFilter'),
          value: editFilterKeys.DUP
        }
      ]
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    activeFilterId() {
      return this.$store.state[`${this.reportNamespace}`].active_filter_id;
    },
    isAllItemsTab() {
      return this.activeFilterId === DEFAULT_FILTER_ID;
    },
    tableStatsLabels() {
      const entries = this.$store.getters[`${this.reportNamespace}/tableStatsLabel`];

      return entries
        .map((entry) => {
          const label = getColValueWithEntry(entry.val, 'code') || entry.val;

          return label;
        })
        .map((label, index, labels) => {
          let formattedLabel = label;

          if (labels[index + 1]?.[0] === ',') {
            formattedLabel += ',';
          }

          if (label[0] === ',') {
            return formattedLabel.slice(1);
          }

          return formattedLabel;
        });
    }
  },
  methods: {
    ...mapActions({
      exportTableToXlsx: 'inventoryReport/exportTableToXlsx',
      exportParamsToXlsx: 'inventoryReport/exportParamsToXlsx',
      importParams: 'inventoryReport/importParams'
    }),
    updateVisibleCols(...args) {
      this.$store.dispatch(`${this.reportNamespace}/updateVisibleCols`, ...args);
    },
    duplicateFilter(...args) {
      this.$store.dispatch(`${this.reportNamespace}/duplicateFilter`, ...args);
    },
    handleFilterEdit() {
      this.showModal(modalsId.REPORT_FILTER, {
        filterId: this.activeFilterId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/toolbar/report-toolbar.scss";
</style>
