<template>
  <div
    :class="{
      'inventory-table--has-nested': pinnedTableVisible
    }"
    :style="tableStyles"
    data-shortcut-report
  >
    <DynamicScroller
      v-if="tableBody"
      :key="rowsNumber"
      ref="tableScroller"
      class="inventory-table__scroller"
      :class="[
        parentTableWrapperClass
      ]"
      :items="tableBody"
      direction="horizontal"
      :min-item-size="columnWidth"
    >
      <template #before>
        <HeaderRow
          :id="headerId"
          :data="tableHead"
          :prop="'code'"
          :nested="nested"
          :fallback-prop="'fallback'"
          :pinned-offset-px="pinnedHeaderWidthOffset"
          :editable-cols="editableReport ? tableColInfo.editableIndexes : []"
          :col-sizes="colSizes"
          :min-col-size="columnWidth"
          :class="{
            'header--expanded': nested && sidebarExpanded
          }"
          @context-menu="handleHeaderContextMenu"
        />
      </template>
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
          emit-resize
        >
          <template v-if="isDropdown(item._columnPosition)">
            <DropdownCol
              :ref="`col-${item._columnPosition}`"
              :data="item.col"
              :dropdown-cols="tableColInfo.dropdownIndexes"
              :dropdowns="dropdowns"
              :col-index="item._columnPosition"
              :selected-rows="currentSelectedItems"
              :rows-info="tableRowsInfo"
              :style="getColStyle(item._columnPosition)"
              :is-ordering-days="isOrderingDays(item._columnPosition)"
              :is-supplier="isSupplier(item._columnPosition)"
              :is-editable="isEditable(item._columnPosition)"
              :data-test-id="`report-tbl-col-${headerByIndex[item._columnPosition]._class.toLowerCase()}`"
              @row-update="(val) => handleRowUpdate(val, item._columnPosition)"
              @cell-select="handleStartItemsSelect"
              @select-mousedown="handleStartItemsSelect"
              @select-mouseenter="handleMoveItemsSelect"
              @select-ctrl-click="handleCurrentItemSelect"
              @select-shift-click="handleRangeItemsSelect"
              @context-menu="(e, rowIndex) => handleContextMenuOpen(e, rowIndex, item._columnPosition)"
            />
          </template>
          <template v-else>
            <TableCol
              :key="item._columnPosition"
              :ref="`col-${item._columnPosition}`"
              :data="item.col"
              :prop="'_val'"
              :is-date="isDate(item._columnPosition)"
              :is-number="isNumber(item._columnPosition)"
              :is-editable="isEditable(item._columnPosition)"
              :is-price="isPrice(item._columnPosition)"
              :is-on-hand="isOnHand(item._columnPosition)"
              :selected-rows="currentSelectedItems"
              :rows-info="tableRowsInfo"
              :style="getColStyle(item._columnPosition)"
              :col-type="getColTypeByIndex(item._columnPosition)"
              :data-test-id="`report-tbl-col-${headerByIndex[item._columnPosition] && headerByIndex[item._columnPosition]._class.toLowerCase()}`"
              @row-update="(val) => handleRowUpdate(val, item._columnPosition)"
              @on-link-click="(i, cell) => handleCellLinkClick(i, item._columnPosition, cell)"
              @select-mousedown="handleStartItemsSelect"
              @select-mouseenter="handleMoveItemsSelect"
              @select-ctrl-click="handleCurrentItemSelect"
              @select-shift-click="handleRangeItemsSelect"
              @context-menu="(e, rowIndex) => handleContextMenuOpen(e, rowIndex, item._columnPosition)"
            />
          </template>
        </DynamicScrollerItem>
      </template>
      <template #after>
        <div
          v-if="!nested"
          class="inventory-table__order"
        >
          <div
            v-for="itemIndex of rowsNumber"
            :key="itemIndex"
            class="inventory-table__cell inventory-table__cell--index"
            :class="{
              editable: isRowEditable(itemIndex - 1)
            }"
          >
            {{ startIndex + itemIndex }}
          </div>
        </div>
        <InventoryTable
          v-if="pinnedTableVisible"
          class="inventory-table inventory-table--nested"
          nested
          :parent-selected-items="selectedItems"
          :parent-old-selected-items="oldSelectedItems"
          :table="pinnedTable"
          :initial-col-sizes="pinnedColSizes"
          @table-update="data => $emit('table-update', data)"
          @start-item-select="handleStartItemsSelect"
          @move-item-select="handleMoveItemsSelect"
          @range-item-select="handleRangeItemsSelect"
          @current-item-select="handleCurrentItemSelect"
          @select-all-rows="handleSelectAllRows"
          @pinned-col-resize="handlePinnedTableColResize"
          @table-context-menu="handleContextMenuOpen"
          @header-context-menu="handleHeaderContextMenu"
        />
      </template>
    </DynamicScroller>
    <HeaderContextMenu
      ref="headerContextMenu"
      :nested="nested"
      :header-index="currentHeaderIndex"
      :header-class="currentHeaderClass"
    />
    <TableContextMenu
      ref="tableContextMenu"
      :nested="nested"
      :selected-items="currentSelectedItems"
      :col-index="contextMenuColIndex"
      :cell-index="contextMenuCellIndex"
      :all-rows-count="tableAllRows"
      @select-all="handleSelectAllRows"
      @edit-cell="handleEditCell"
      @substitute="handleSubstitute"
      @remove-from-import="handleRemoveFromImport"
      @find-in-demand="openItemCodeLink"
      @explain-calculation="openExplainCalculation"
      @open-bom="openBomLink"
    />
    <template v-if="!nested">
      <ExplainCalculation :id="modalsId.EXPLAIN_CALCULATION" />
      <SubstituteItem :id="modalsId.SUBSTITUTE_AN_ITEM" />
      <RemoveFromImport :id="modalsId.REMOVE_FROM_IMPORT" />
      <ConfirmModal
        :id="modalsId.CONFIRM_INV_CONTEXT_MENU"
        width="290"
        without-cancel
      />
      <NoteModal :id="modalsId.ADD_NOTE" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { itemSelect } from '@/mixins/itemSelect';
import { colResize } from '@/mixins/colResize';
import { shortcut } from '@/mixins/shortcut';
import HeaderRow from './HeaderRow.vue';
import TableCol from './TableCol.vue';
import DropdownCol from './DropdownCol.vue';
import InventoryTable from './InventoryTable.vue';
import SubstituteItem from '@/components/Modals/SubstitutionRules/SubstituteItem.vue';
import RemoveFromImport from '@/components/Modals/SubstitutionRules/RemoveFromImport.vue';
import ConfirmModal from '@/components/Modals/Shared/ConfirmModal.vue';
import NoteModal from '@/components/Modals/Shared/NoteModal.vue';
import ExplainCalculation from '@/components/Modals/ExplainCalculation.vue';
import TableContextMenu from '@/components/InventoryTable/ContextMenu/TableContextMenu.vue';
import HeaderContextMenu from '@/components/InventoryTable/ContextMenu/HeaderContextMenu.vue';
import modalsId from '@/config/shared/modalsId.config';
import { bomTypes } from '@/config/item/bom.config';
import { PAGE_SIZE, namespaceByRoute } from '@/config/report';
import { tableColsClasses } from '@/config/report/inventoryReport';
import { routeNames } from '@/config/router/router.config';
import { uiTabByRoute, uiSettingsKeys } from '@/config/users/uiSettings.config';
import { customDebounce } from '@/helpers/shared/listeners';
import { formatNumber, unformatNumber } from '@/helpers/format/formatNumber';
import { getFindInQuery } from '@/helpers/report/inventoryReport';
import {
  contextMenuAdjustment,
  contextMenuKeys,
  fgsActionTypes
} from '@/config/shared/contextMenu.config';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import { copyToClipboard } from '@/helpers/utils/clipboard';

export default {
  name: 'InventoryTable',
  components: {
    HeaderRow,
    TableCol,
    DropdownCol,
    SubstituteItem,
    RemoveFromImport,
    ConfirmModal,
    HeaderContextMenu,
    TableContextMenu,
    ExplainCalculation,
    InventoryTable,
    NoteModal
  },
  mixins: [itemSelect, colResize, modal, access, shortcut],
  props: {
    table: {
      type: Object,
      required: true
    },
    startIndex: {
      type: Number,
      default: 0
    },
    parentSelectedItems: {
      type: Array,
      default: () => []
    },
    parentOldSelectedItems: {
      type: Array,
      default: () => []
    },
    initialColSizes: {
      type: Array,
      default: () => []
    },
    nested: Boolean
  },
  data() {
    return {
      modalsId,
      formatNumber,
      unformatNumber,
      contextMenuKeys,
      PAGE_SIZE,
      cellHeight: 27,
      pinnedColsDynamicSize: 0,
      pinnedColSizes: [],
      currentColPosition: '',
      currentHeaderClass: '',
      currentHeaderIndex: '',
      contextMenuColIndex: null,
      contextMenuCellIndex: null,
      linkHandlersMap: {
        [tableColsClasses.ITEMCODE]: this.openItemCodeLink,
        [tableColsClasses.BOMTYPE]: this.openBomLink,
        [tableColsClasses.SOURCE_FROM]: this.findInInventory
      },
      minColumnWidth: 40
    };
  },
  provide() {
    return {
      formatNumber: this.formatNumber,
      unformatNumber: this.unformatNumber,
      locationByIndex: this.locationByIndex,
      channelByIndex: this.channelByIndex,
      itemByIndex: this.itemByIndex,
      linkPayloadByIndex: this.linkPayloadByIndex,
      setSelectedRow: this.setSelectedRow
    };
  },
  computed: {
    ...mapState({
      dropdowns: (state) => state.inventoryReport.dropdowns,
      sidebarExpanded: (state) => state.sidebar,
      ui_settings: (state) => state.user.ui_settings
    }),
    ...mapGetters({
      hasBom: 'project/hasBom'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    reportUiSettings() {
      return this.ui_settings[uiTabByRoute[this.$sl_routeName]];
    },
    columnWidth() {
      return this.reportUiSettings?.[uiSettingsKeys.COLUMNS_WIDTH]?.default || 110;
    },
    pinnedColumns() {
      return this.$store.state[this.reportNamespace].pinnedColumns;
    },
    tableId() {
      return this.$store.getters[`${this.reportNamespace}/tableId`];
    },
    serverSideColSizes() {
      return this.$store.state[this.reportNamespace].colSizes || [];
    },
    pinnedTable() {
      return this.$store.getters[`${this.reportNamespace}/pinnedReportTable`];
    },
    pinnedTableColLength() {
      return this.pinnedTable?.head?.col?.length || 0;
    },
    pinnedTableVisible() {
      return this.pinnedTableColLength && !this.nested;
    },
    currentSelectedItems() {
      return this.nested ? this.parentSelectedItems : this.selectedItems;
    },
    currentOldSelectedItems() {
      return this.nested ? this.parentOldSelectedItems : this.oldSelectedItems;
    },
    tableBody() {
      return this.table.body; // here and after look Report.vue:28
    },
    itemColChanTypeIndexes() {
      return this.$store.getters[`${this.reportNamespace}/itemLocChanTypeIndexes`];
    },
    locationByIndex() {
      return this.$store.getters[`${this.reportNamespace}/locationByIndex`];
    },
    channelByIndex() {
      return this.$store.getters[`${this.reportNamespace}/channelByIndex`];
    },
    itemByIndex() {
      return this.$store.getters[`${this.reportNamespace}/itemByIndex`];
    },
    linkPayloadByIndex() {
      return this.$store.getters[`${this.reportNamespace}/linkPayloadByIndex`];
    },
    headerByIndex() {
      return this.$store.getters[`${this.reportNamespace}/headerByIndex`];
    },
    tableColInfo() {
      return this.$store.getters[`${this.reportNamespace}/tableColInfo`];
    },
    tableHead() {
      return this.table.head.col;
    },
    tableRowsInfo() {
      return this.table.rowsInfo;
    },
    tableAllRows() {
      return +this.table.N_rows;
    },
    rowsNumber() {
      return +this.tableBody[0]?.col.length || 0;
    },
    pinnedTableSize() {
      if (!this.nested) {
        return;
      }

      const staticSize = this.pinnedTableColLength * this.columnWidth;

      const pinnedColSizes = this.colSizes.reduce((acc, size, index) => {
        const isPinnedColumn = this.pinnedColumns.find(col => {
          return this.headerByIndex[index]?._class === col.class
            && this.headerByIndex[index]?._index === col.index;
        });

        if (isPinnedColumn) {
          acc.push(size);
        }

        return acc;
      }, []);

      return pinnedColSizes.reduce((acc, size) => {
        acc += size - this.columnWidth;

        return acc;
      }, staticSize);
    },
    pinnedColsStaticSize() {
      return this.pinnedTableColLength * this.columnWidth;
    },
    pinnedHeaderWidthOffset() {
      if (this.nested || !this.pinnedTableColLength) {
        return '0';
      }

      return this.pinnedColsDynamicSize + 'px';
    },
    pinnedTableWidthOffset() {
      if (this.nested || !this.pinnedTableColLength) {
        return 0;
      }

      const offset = this.pinnedColsDynamicSize - this.pinnedColsStaticSize;

      return offset + this.pinnedColsStaticSize;
    },
    parentTableWrapperClass() {
      return `inventory-table__scroller--${this.pinnedTableColLength}`;
    },
    selectedRowItemCode() {
      const item = this.itemByIndex(this.lastSelectedItem);

      return item?._val || null;
    },
    editableReport() {
      return this.$sl_routeName !== routeNames.REPORTS && this.$sl_tabEditable;
    },
    tableStyles() {
      return {
        height: this.nested
          ? `${+this.pinnedTable.rowsInfo.length * this.cellHeight}px`
          : 'auto'
      };
    },
    headerId() {
      return this.nested ? 'inventory-header-nested' : 'inventory-header';
    },
    storeSelectedRows() {
      return this.$store.state[`${this.reportNamespace}`]?.selectedRows || [];
    },
    isBomDisabled() {
      return !this.getActionFgs(contextMenuFgs.ITEM_BOM, fgsActionTypes.ENABLED);
    }
  },
  watch: {
    tableId() {
      this.resetSelection();
    },
    currentSelectedItems: customDebounce(function(value) {
      if (this.itemColChanTypeIndexes?.itemTypeIdx === -1) {
        return;
      }

      if (!this.reportNamespace) {
        return;
      }

      const rowIndex = value.at(-1);

      if (rowIndex >= 0) {
        this.setBomEntry(this.getBomEntry(rowIndex));
      }
    }, 300),
    pinnedTableSize(val) {
      if (!this.nested) {
        return;
      }

      this.$emit('pinned-col-resize', val, this.colSizes);
    },
    pinnedTableColLength(val, oldVal) {
      if (!this.nested) {
        return;
      }

      if (val < oldVal) {
        this.updateColSize(this.currentColPosition, 0);
      }
    },
    pinnedTableWidthOffset(val) {
      this.updatePinnedTableWidthOffset(val);
    },
    selectedItems(val) {
      this.$emit('selected-items-change', val);

      this.setSelectedRows(val);
    }
  },
  mounted() {
    this.setSelectedItems(this.storeSelectedRows);
    this.scrollToSelectedRow();
    this.initResizer();
    this.updatePinnedTableWidthOffset();
  },
  updated() {
    this.setSelectedItems(this.storeSelectedRows);
    this.initResizer();
    this.updatePinnedTableWidthOffset();
  },
  beforeDestroy() {
    this.$emit('pinned-col-resize', 0, []);
  },
  methods: {
    ...mapActions({
      setBomEntry: 'bom/setSelectedEntry',
      getItemClones: 'substitutions/getItemClones',
      clearOverrides: 'inventoryReport/clearOverrides'
    }),
    initResizer() {
      this.initResize({
        tableId: this.headerId,
        colSelector: '.inventory-table__header',
        resizerHeight: '100%',
        minWidth: this.minColumnWidth,
        lazyResize: true,
        initialColSizes: this.serverSideColSizes,
        updateSizeCallback: (data) => this.saveColResize(data)
      });
    },
    saveColResize(data) {
      this.$store.dispatch(`${this.reportNamespace}/handleColResize`, data);
    },
    handlePinnedTableColResize(size, sizes) {
      this.pinnedColsDynamicSize = size;
      this.pinnedColSizes = sizes;
    },
    updatePinnedTableWidthOffset(val = this.pinnedTableWidthOffset) {
      if (this.nested) {
        this.$emit('pinned-col-resize', this.pinnedTableSize, this.colSizes);

        return;
      }

      const scrollerItemWrapper = document.querySelector('.vue-recycle-scroller__item-wrapper');

      scrollerItemWrapper.style.marginLeft = val + 'px';
    },
    scrollToSelectedRow() {
      if (this.nested) {
        return;
      }

      if (this.selectedItems.length) {
        const scrollContainer = this.$refs.tableScroller.$el;

        scrollContainer.scrollTop = this.selectedItems[0] * this.cellHeight;
      }
    },
    setSelectedRows(rows) {
      return this.$store.dispatch(`${this.reportNamespace}/setSelectedRows`, rows);
    },
    isRowEditable(index) {
      return this.editableReport && this.tableRowsInfo[index]?.editable !== '0';
    },
    isDropdown(index) {
      return Object.values(this.tableColInfo.dropdownIndexes).includes(+index);
    },
    isDate(index) {
      return this.tableColInfo.dateIndexes.includes(+index);
    },
    isNumber(index) {
      return this.tableColInfo.numberIndexes.includes(+index);
    },
    isPrice(index) {
      return this.tableColInfo.priceIndexes.includes(+index);
    },
    isOrderingDays(index) {
      return this.tableColInfo.dropdownIndexes[tableColsClasses.ITEM_ORDERING_DAYS] === +index;
    },
    isSupplier(index) {
      return this.tableColInfo.dropdownIndexes[tableColsClasses.SUPPLIER] === +index;
    },
    isOnHand(index) {
      return this.tableColInfo.onHandIndex === +index;
    },
    isEditable(index) {
      return this.editableReport && this.tableColInfo.editableIndexes.includes(+index);
    },
    getColTypeByIndex(index) {
      return this.headerByIndex?.[index]?._type;
    },
    isSelectedDifferentValues(col) {
      if (this.currentSelectedItems.length <= 1) {
        return null;
      }

      const start = this.currentSelectedItems.at(0);
      const end = this.currentSelectedItems.at(-1);
      const selected = col.data.slice(start, end);
      const firstValue = this.getCellValue(selected[0]);

      return selected.some((item, index) => {
        if (index) {
          return this.getCellValue(item) !== firstValue;
        }
      });
    },
    getColStyle(index) {
      return {
        width: `${this.colSizes[index] || this.columnWidth}px`
      };
    },
    getCellValue(item) {
      return item._val;
    },
    copyItemCode() {
      copyToClipboard(this.selectedRowItemCode);
    },
    handleRowUpdate({ value, cellIndex }, colIndex) {
      if (!value) {
        return this.clearTableOverrides(cellIndex, colIndex);
      }

      const rowsToUpdate = this.currentSelectedItems.includes(cellIndex)
        ? this.currentSelectedItems
        : this.currentOldSelectedItems;

      const rows = rowsToUpdate.reduce((items, rowIndex) => {
        const item = this.itemByIndex(rowIndex);
        const location = this.locationByIndex(rowIndex);

        items.push({
          item: item?._val,
          location: location?._val
        });

        return items;
      }, []);

      this.$emit('table-update', {
        rows,
        value,
        class: this.headerByIndex[colIndex]._class,
        index: this.headerByIndex[colIndex]._index,
        isAllRows: rows.length === this.tableAllRows
      });
    },
    clearTableOverrides(cellIndex, colIndex) {
      const rowsToUpdate = this.currentSelectedItems.includes(cellIndex)
        ? this.currentSelectedItems
        : this.currentOldSelectedItems;

      const rows = rowsToUpdate.reduce((items, rowIndex) => {
        const item = this.itemByIndex(rowIndex);
        const location = this.locationByIndex(rowIndex);

        items.push({
          _item: item?._val,
          _location: location?._val
        });

        return items;
      }, []);

      const isAllRows = rows.length === this.tableAllRows;

      this.clearOverrides({
        rows: isAllRows ? [] : rows,
        _class: this.headerByIndex[colIndex]._class,
        _index: this.headerByIndex[colIndex]._index
      });
    },
    handleCellLinkClick(rowIndex, colIndex, item) {
      const colClass = this.headerByIndex[colIndex]?._class;

      if (this.linkHandlersMap[colClass]) {
        this.linkHandlersMap[colClass](rowIndex, item);
      }
    },
    async openItemCodeLink(rowIndex) {
      const payload = this.linkPayloadByIndex(rowIndex);

      await this.$store.dispatch('demand/tree/openTreeNodeLink', payload);

      return this.$router.push({ name: routeNames.DEMAND });
    },
    getFindInQueryData(item) {
      const params = {
        item: this.selectedRowItemCode,
        loc: item._val
      };

      return params;
    },
    async findInInventory(_, item) {
      this.$router.push({
        name: routeNames.INVENTORY,
        query: {
          search: getFindInQuery(this.getFindInQueryData(item))
        },
        params: {
          link: true
        }
      });
    },
    getBomEntry(index) {
      return {
        ...this.linkPayloadByIndex(index),
        route: this.$route.name
      };
    },
    async openBomLink(rowIndex) {
      if (!this.hasBom || !this.$sl_hasAccess(this.$sl_features.bomAccess)) {
        return;
      }

      await this.setBomEntry(this.getBomEntry(rowIndex));

      await this.$router.push({
        name: routeNames.BILL_OF_MATERIALS,
        params: {
          type: bomTypes.ITEM
        }
      });
    },
    handleHeaderContextMenu(event, headerItem) {
      if (this.nested) {
        this.currentColPosition = headerItem._columnPosition;

        return this.$emit('header-context-menu', event, headerItem);
      }

      this.currentHeaderClass = headerItem._class;
      this.currentHeaderIndex = headerItem._index;
      this.currentColPosition = headerItem._columnPosition;

      this.$refs.headerContextMenu.$refs.contextMenu.showMenu(
        event,
        this.lastSelectedItem,
        contextMenuAdjustment.pageX,
        null,
        '.inventory-table__scroller'
      );
    },
    handleSelectAllRows() {
      if (this.nested) {
        return this.$emit('select-all-rows');
      }

      this.selectAllRows(this.tableAllRows);
    },
    handleEditCell(event) {
      const col = this.$refs[`col-${this.contextMenuColIndex}`];

      if (!col) {
        return;
      }

      col.handleCellFocus(
        this.contextMenuCellIndex,
        event,
        this.isSelectedDifferentValues(col) && '*'
      );
    },
    async handleSubstitute() {
      await this.getItemClones(this.selectedRowItemCode);

      this.showModal(modalsId.SUBSTITUTE_AN_ITEM, {
        activeItemCode: this.selectedRowItemCode
      });
    },
    async handleRemoveFromImport() {
      await this.getItemClones(this.selectedRowItemCode);

      this.showModal(modalsId.REMOVE_FROM_IMPORT, {
        activeItemCode: this.selectedRowItemCode
      });
    },
    openExplainCalculation() {
      if (this.lastSelectedItem === null) {
        return;
      }

      this.showModal(modalsId.EXPLAIN_CALCULATION, {
        payload: this.linkPayloadByIndex(this.lastSelectedItem),
        tableRowsNumber: this.rowsNumber,
        tableRowIndex: this.lastSelectedItem
      });
    },
    handleContextMenuOpen(event, cellIndex, colIndex) {
      if (this.nested) {
        return this.$emit('table-context-menu', event, cellIndex, colIndex);
      }

      this.contextMenuColIndex = colIndex;
      this.contextMenuCellIndex = cellIndex;

      if (!this.currentSelectedItems.includes(cellIndex)) {
        this.setSelectedRow(cellIndex);
      }

      this.$refs.tableContextMenu.$refs.contextMenu.showMenu(
        event,
        this.currentSelectedItems[0],
        contextMenuAdjustment.pageX,
        null,
        '.inventory-table__scroller'
      );
    },
    handleStartItemsSelect(...args) {
      if (this.nested) {
        return this.$emit('start-item-select', ...args);
      }

      this.startItemsSelect(...args);
    },
    handleMoveItemsSelect(...args) {
      if (this.nested) {
        return this.$emit('move-item-select', ...args);
      }

      this.moveItemsSelect(...args);
    },
    handleCurrentItemSelect(...args) {
      if (this.nested) {
        return this.$emit('current-item-select', ...args);
      }

      this.currentItemSelect(...args);
    },
    handleRangeItemsSelect(...args) {
      if (this.nested) {
        return this.$emit('range-item-select', ...args);
      }

      this.rangeItemsSelect(...args);
    },
    getRowActionFgs(rowIndex) {
      const row = this.table?.body?.[0]?.col?.[rowIndex];

      return {
        [fgsActionTypes.ENABLED]: row?._enabledAcs,
        [fgsActionTypes.VISIBLE]: row?._visibleAcs
      };
    },
    getActionFgs(action, type) {
      const cellActionFgs = this.getRowActionFgs(this.selectedItems[0]);

      return !!(cellActionFgs[type] & action);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inventory-table/table.scss";
@import "@/style/components/inventory-table/table-row.scss";
</style>
