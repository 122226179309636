var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlContextMenu',{ref:"contextMenu",attrs:{"element-id":_vm.menuElementId,"options":_vm.tableContextMenuOptions},on:{"option-clicked":_vm.handleOptionClick},scopedSlots:_vm._u([{key:_vm.contextMenuKeys.FILTER_BY,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FILTER_BY, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FILTER_BY, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FILTER_ADD,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.ADD_TO_FILTER, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.ADD_TO_FILTER, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FILTER_BY_BOM,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FILTER_BY_BOM, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FILTER_BY_BOM, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.COPY_ITEM_TO_CLIPBOARD,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.COPY_ITEM_CODE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.COPY_ITEM_CODE, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.COPY_ITEM.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_DEMAND,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_DEMAND, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_DEMAND, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_INVENTORY,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_IR, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_IR, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_DC,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_DC, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_DC, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_REPORTS,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_FR, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_FR, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.ITEM_BOM,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.ITEM_BOM, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.ITEM_BOM, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.OPEN_MATERIAL_PRODUCT_TREE.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.REMOVE_FROM_IMPORT,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.REMOVE_FROM_IMPORT, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.REMOVE_FROM_IMPORT, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.REMOVE_FROM_IMPORT.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.SUBSTITUTE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.SUBSTITUTE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.SUBSTITUTE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.SELECT_ALL,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [_c('OptionItem',{attrs:{"handler":handler},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.SELECT_ALL_ROWS.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")])]}},{key:_vm.contextMenuKeys.EDIT,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.EDIT, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.EDIT, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.COPY_QTY_TO_OVERRIDE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.COPY_QTY_TO_OVERRIDE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.COPY_QTY_TO_OVERRIDE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.ADD_NOTE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.ADD_NOTE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.ADD_NOTE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.EDIT_NOTE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.EDIT_NOTE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.EDIT_NOTE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.CLEAR_OVERRIDES,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.CLEAR_OVERRIDES, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.CLEAR_OVERRIDES, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.EXPLAIN_CALCULATION,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.EXPLAIN_CALCULATION, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.EXPLAIN_CALCULATION, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.RESET_NEXT_ORDER_DATE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.RESET_NEXT_ORDER_DATE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.RESET_NEXT_ORDER_DATE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }