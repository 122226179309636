<template>
  <DefaultModal
    :id="id"
    width="910"
    :content-class="'modal--high'"
    :title="$t('ExplainInventory.Ui.:Caption')"
    :loading="isLoading"
    maximizable
    @created="onCreated"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <v-card-text class="explain-calculation">
      <template v-if="!isLoading && explanationData">
        <div class="explain-calculation__header">
          <div class="explain-calculation__header-col">
            <span>
              {{ $t('ExplainInventory.Ui.lbItemDesc') }}
            </span>
            <span>
              {{ $t('ExplainInventory.Ui.lbLocationDesc') }}
            </span>
            <span>
              {{ $t('ExplainInventory.Ui.lbSafetyStockDesc') }}
            </span>
            <span>
              {{ $t('ExplainInventory.Ui.lbDebtReceivedDesc') }}
            </span>
            <span>
              {{ $t('ExplainInventory.Ui.lbDebtBundleReceivedDesc') }}
            </span>
            <span>
              {{ $t('ExplainInventory.Ui.lbDebtLeakedDesc') }}
            </span>
            <span>
              {{ $t('ExplainInventory.Ui.InvHorizonDesc') }}
            </span>
          </div>
          <div class="explain-calculation__header-col">
            <span>
              {{ explanationData.item }}
            </span>
            <span>
              {{ explanationData.location }}
            </span>
            <span class="color--grer opaque">
              {{ explanationData.safetyStock }}
            </span>
            <span>
              <span class="color--grer opaque">
                {{ explanationData.debtReceived.current }}
                /
                {{ explanationData.debtReceived.max }}
              </span>
              , accum.
              {{ explanationData.debtReceived.accumulated }}
            </span>
            <span>
              <span class="color--grer opaque mr-4">
                {{ explanationData.minOrder.initial }}
              </span>
              {{ arrowRight }}
              {{ explanationData.minOrder.canceled }}
            </span>
            <span>
              {{ explanationData.debtPassed.current }} / {{ explanationData.debtPassed.max }}
            </span>
            <span>
              {{ explanationData.horizon }} {{ $t(`Common.AfterNum.${explanationData.verbalCycle}`) }}
              <v-checkbox
                id="manufacture-details"
                class="sl-checkbox"
                :label="$t('ExplainInventory.Ui.chkDetails')"
                :value="manufactureDetailsCheckbox"
                :disabled="!+explanationData.hasManufactureDetails"
                :ripple="false"
                solo
                @change="handleManufactureDetailsChange"
              />
              <v-checkbox
                id="backorders-details"
                class="sl-checkbox"
                :label="$t('Web.ExplainCalculation.ToShipDetails')"
                :value="backordersDetailsCheckbox"
                :disabled="!+explanationData.backordersDetailsAvailable"
                :ripple="false"
                solo
                @change="handleBackordersDetailsChange"
              />
            </span>
          </div>
          <div class="explain-calculation__header-col explain-calculation__header-col--end">
            <v-btn
              class="sl-button sl-button--h24"
              small
              :disabled="isPrevBtnDisabled"
              @click="getPrevItemExplanation"
            >
              <icon
                data="@icon/arrow-down.svg"
                class="size-18 rotate-180"
              />
            </v-btn>
            <v-btn
              class="sl-button sl-button--h24"
              small
              :disabled="isNextBtnDisabled"
              @click="getNextItemExplanation"
            >
              <icon
                data="@icon/arrow-down.svg"
                class="size-18"
              />
            </v-btn>
          </div>
        </div>
        <div
          v-if="explanationData.confusingDate"
          class="explain-calculation__warning"
        >
          {{ updateWarning }}
        </div>
        <div
          id="explanation-table"
          ref="table"
          class="table"
        >
          <RecycleScroller
            class="explain-calculation__scroller"
            :items="tableData"
            :item-size="columnWidth"
            direction="horizontal"
          >
            <template #before>
              <ul class="explain-calculation__titles">
                <li class="explain-calculation__title" />
                <li
                  v-for="(header, headerIndex) of explanationData.rowsInfo"
                  :key="header.name.code + headerIndex"
                  v-tooltip="getTooltip(getTableHeader(header.name))"
                  class="explain-calculation__title"
                >
                  {{ getTableHeader(header.name) }}
                </li>
              </ul>
            </template>
            <template v-slot="{ item, index: colIndex }">
              <div class="explain-calculation__table-column">
                <div
                  v-for="(cell, rowIndex) in item.col"
                  :key="item.id + rowIndex"
                  class="cell"
                  :class="getCellBackgroundClass(rowIndex - 1, colIndex)"
                  @click="handleCellSelect(cell, rowIndex - 1, colIndex)"
                >
                  <div
                    v-if="cell.icons"
                    v-tooltip="getTooltipValue(cell.tooltips)"
                    class="cell--icon"
                  >
                    <icon
                      v-for="icon in cell.icons"
                      :key="icon"
                      class="size-16"
                      :data="require(`@icon/explain-calculation/${icon}.svg`)"
                    />
                  </div>
                  <div
                    v-else-if="cell.val"
                    v-tooltip="getTooltipValue(cell.tooltips)"
                    :class="{ 'has-note': hasNote(cell) }"
                  >
                    <template v-if="cell.val._type === 'expr'">
                      <span :class="getCellTextClass(cell)">
                        {{ cell.val.lhs }}
                      </span>
                      <span :class="getCellTextClass(cell, 1)">
                        {{ cell.val.operator }} {{ cell.val.rhs }}
                      </span>
                    </template>
                    <span
                      v-else
                      :class="getCellTextClass(cell)"
                    >
                      {{ cell.val.__text }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </RecycleScroller>
        </div>
      </template>
    </v-card-text>
    <template #footer>
      <div class="explain-calculation__footer">
        <div class="explain-calculation__footer-item">
          <p class="color--expn">
            {{ $t('ExplainInventory.Ui.lbInitial') }}
          </p>
          <p>
            {{ $t('ExplainInventory.Ui.lbCalculated') }}
          </p>
          <p class="background--gree">
            +
          </p>
          <p class="background--red-">
            -
          </p>
          <p class="background--exhi">
            {{ $t('ExplainInventory.Ui.lbCodependent') }}
          </p>
        </div>
        <v-btn
          class="sl-button"
          text
          @click="hideModal(id)"
        >
          {{ $t('Common.Close') }}
        </v-btn>
      </div>
    </template>
    <template #loader>
      <ExplainCalculationSkeleton />
    </template>
  </DefaultModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import DefaultModal from '@/components/Modals/DefaultModal.vue';
import ExplainCalculationSkeleton from '@/components/Skeleton/Modals/ExplainCalculationSkeleton.vue';
import { unicodeSymbols } from '@/config/utils/unicodes.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { getColValueWithEntry } from '@/helpers/locale/getColValueWithEntry';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ExplainCalculationModal',
  components: {
    DefaultModal,
    ExplainCalculationSkeleton
  },
  mixins : [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ...unicodeSymbols,
      getTooltip,
      columnWidth: 85,
      tableRowIndex: -1,
      tableRowsNumber: null,
      manufactureDetailsCheckbox: false,
      backordersDetailsCheckbox: false,
      selectedRowIndex: 0,
      selectedColIndex: 0,
      activeRelatedCells: [],
      isButtonDisabled: false,
      payload: null,
      isLoading: false
    };
  },
  inject: [
    'linkPayloadByIndex',
    'setSelectedRow'
  ],
  computed: {
    ...mapState({
      explanationData: (state) => state.inventoryReport.explanationTable
    }),
    updateWarning() {
      const date = dateByLocaleKey(this.explanationData.confusingDate, localeDateKeys.YMD);

      return this.$t('ExplainInventory.Other.WarnConfusingDates', { 1: date });
    },
    tableData() {
      return this.explanationData?.tableData;
    },
    isNextBtnDisabled() {
      return this.isButtonDisabled || this.tableRowIndex === this.tableRowsNumber - 1;
    },
    isPrevBtnDisabled() {
      return this.isButtonDisabled || !this.tableRowIndex;
    }
  },
  watch: {
    explanationData(data) {
      this.isButtonDisabled = !data;
    }
  },
  methods: {
    ...mapActions({
      fetchExplanation: 'inventoryReport/fetchExplanation'
    }),
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(props, key)) {
          this[key] = props[key];
        }
      });
    },
    async onModalShow() {
      try {
        this.isLoading = true;

        await this.fetchExplanation(this.payload);
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        });
      }
    },
    onModalHide() {
      this.resetSelectedCell();
      this.tableRowsNumber = null;
      this.tableRowIndex = -1;
      this.manufactureDetailsCheckbox = false;
      this.backordersDetailsCheckbox = false;
      this.payload = null;
    },
    getUpdatePayload() {
      const { item, location } = this.linkPayloadByIndex(this.tableRowIndex);

      return {
        item,
        location,
        manufactureDetails: this.manufactureDetailsCheckbox,
        backordersDetails: this.backordersDetailsCheckbox
      };
    },
    handleManufactureDetailsChange(value) {
      this.manufactureDetailsCheckbox = Boolean(value);

      this.fetchExplanation(this.getUpdatePayload());
    },
    handleBackordersDetailsChange(value) {
      this.backordersDetailsCheckbox = Boolean(value);

      this.fetchExplanation(this.getUpdatePayload());
    },
    handleCellSelect(cell, rowIndex, colIndex) {
      if (rowIndex === -1) {
        return;
      }

      this.activeRelatedCells = cell.related || [];
      this.selectedRowIndex = rowIndex;
      this.selectedColIndex = colIndex;
    },
    resetSelectedCell() {
      this.selectedRowIndex = 0;
      this.selectedColIndex = 0;
      this.activeRelatedCells = [];
    },
    hasNote(cell) {
      return cell.tooltips?.length;
    },
    isActiveCell(rowIndex, colIndex) {
      return this.selectedRowIndex === rowIndex && this.selectedColIndex === colIndex;
    },
    getTableHeader(header) {
      return getColValueWithEntry(header, 'code');
    },
    getCellTextClass(cell, index = 0) {
      if (!cell.foregrounds) {
        return '';
      }

      return `color--${cell.foregrounds[index]}`;
    },
    getCellBackgroundClass(rowIndex, colIndex) {
      if (this.isActiveCell(rowIndex, colIndex)) {
        return 'cell--active';
      }

      const cellInfo = this.activeRelatedCells.find(cell => {
        return rowIndex === +cell.row && colIndex === +cell.col;
      });

      if (!cellInfo) {
        return;
      }

      return `background--${cellInfo.background}`;
    },
    getTooltipValue(tooltips) {
      if (!tooltips) {
        return;
      }

      const tooltipsContent =  tooltips.map(tooltip => getColValueWithEntry(tooltip, '_code'));

      return {
        html: true,
        content: tooltipsContent.join('<br>')
      };
    },
    getPrevItemExplanation() {
      this.tableRowIndex--;

      this.getItemExplanation();
    },
    getNextItemExplanation() {
      this.tableRowIndex++;

      this.getItemExplanation();
    },
    getItemExplanation() {
      this.isButtonDisabled = true;
      this.setSelectedRow(this.tableRowIndex);

      this.resetSelectedCell();

      this.fetchExplanation(this.getUpdatePayload());
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/explain-calculation.scss";
</style>
