<template>
  <div class="inventory-table__col">
    <div
      v-for="(item, i) in data"
      :key="i"
      :ref="`cell-${i}`"
      v-tooltip="getTooltipValue(item)"
      tabindex="-1"
      class="inventory-table__cell"
      :class="{
        [`background--${item._background}`]: item._background,
        [`color--${item._foreground}`]: item._foreground,
        'background--darken': isItemSelected(i),
        'inventory-table__cell--editable': isEditable,
        'inventory-table__cell--number': isNumber,
        'has-note': hasTooltip(item)
      }"
      @click.left.exact="() => handleCellFocus(i)"
      @mousedown.left.exact="$emit('select-mousedown', i)"
      @mouseenter.exact="$emit('select-mouseenter', i)"
      @click.ctrl.exact="$emit('select-ctrl-click', i)"
      @click.shift.exact="$emit('select-shift-click', i)"
      @click.right.prevent.stop="$emit('context-menu', $event, i)"
    >
      <TableInput
        v-if="!isNumber && isEditable"
        :ref="`input-${i}`"
        :value="inputValue(item)"
        :disabled="isDisabled(item, i)"
        :text-value="itemValue(item, i)"
        :max-length="9999"
        type="text"
        focus-visible
        off-editable-bg
        @input="(val) => handleCellUpdate(val, i)"
        @submit="(val) => handleCellSubmit(val, i)"
      />
      <SimpleTableInput
        v-else-if="isNumber && isEditable"
        :ref="`input-${i}`"
        :value="inputValue(item)"
        :text-value="itemValue(item, i)"
        :disabled="isDisabled(item, i)"
        :max-length="9999"
        type="text"
        :input-regexp="numInputRegexp"
        focus-visible
        off-editable-bg
        @input="(val) => handleCellUpdate(val, i)"
        @submit="(val) => handleCellSubmit(val, i)"
      />
      <div
        v-else-if="item._foreground === 'link'"
        class="link"
        :class="{
          'link--disabled': !itemValue(item, i)
        }"
        @click="handleLinkClick(item, i)"
      >
        {{ itemValue(item, i) }}
      </div>
      <template v-else>
        {{ itemValue(item, i) }}
      </template>
    </div>
  </div>
</template>

<script>
import { access } from '@/mixins/access';
import SimpleTableInput from '@/components/Shared/Form/SimpleTableInput.vue';
import TableInput from '@/components/Shared/Form/TableInput.vue';
import { inventoryCellFgs as fgs } from '@/config/shared/fgs.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { getNoteTooltip } from '@/helpers/shared/note';
import { getTooltip } from '@/helpers/shared/tooltip';
import { toArray } from '@/helpers/utils/toArray';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'InventoryTableColumn',
  components: {
    TableInput,
    SimpleTableInput
  },
  mixins: [access],
  props: {
    data: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'common'
    },
    prop: {
      type: String,
      required: true
    },
    rowsInfo: {
      type: Array,
      default: () => []
    },
    selectedRows: {
      type: Array,
      required: true
    },
    colType: {
      type: String,
      required: false,
      default: 'int'
    },
    isDate: Boolean,
    isNumber: Boolean,
    isPrice: Boolean,
    isEditable: Boolean,
    isOnHand:Boolean
  },
  data() {
    return {
      formatNumber: this.formatNumber,
      localeConstValues: [
        'Transfer',
        'Purchase',
        'Constant level',
        '2 types',
        'Intermittent',
        'Preorder',
        'Approved',
        'Unapproved',
        'Undecided',
        'Checked',
        'Needs attention',
        'Linear trend',
        'Seasonal & trend'
      ],
      floatTypes: ['float', 'double', 'percent']
    };
  },
  inject: [
    'formatNumber'
  ],
  computed: {
    isFloat() {
      return this.floatTypes.includes(this.colType);
    },
    isInt() {
      return this.colType === 'int';
    },
    numInputRegexp() {
      if (this.isInt) {
        return regExp.intInput;
      }

      return regExp.simpleInput;
    }
  },
  methods: {
    getCurrency(index) {
      return this.rowsInfo[index].currency;
    },
    handleCellFocus(index, _, value) {
      if (!this.isEditable) {
        return;
      }

      const inputWrapperNode = this.$refs[`input-${index}`]?.[0]?.$el;

      if (inputWrapperNode) {
        inputWrapperNode.focus();

        if (value) {
          this.$nextTick(() => {
            const inputNode = inputWrapperNode.querySelector('.table-input');

            inputNode.value = value;
          });
        }
      }
    },
    handleLinkClick(item, index) {
      if (!this.itemValue(item, index)) {
        return;
      }

      this.$emit('on-link-click', index, item);
    },
    handleCellUpdate(value, index) {
      this.$set(this.data, index, {
        ...this.data[index],
        _edit: value
      });
    },
    handleCellSubmit(value, index) {
      this.$emit('row-update', { value, cellIndex: index });
    },
    isDisabled(item, index) {
      return !this.$sl_tabEditable || !this.isEditable || !(+item._fgs & fgs.EDITABLE) || this.rowsInfo[index]?.editable === '0';
    },
    isItemSelected(index) {
      return this.selectedRows.includes(index);
    },
    inputValue(item) {
      if (Object.prototype.hasOwnProperty.call(item, '_edit')) {
        return item._edit;
      }

      return this.textValue(item);
    },
    textValue(item) {
      return item[this.prop];
    },
    itemValue(item, i) {
      if (!item || i === -1) {
        return ''; // send location="", if there is no location col in the table;
      }

      if (this.isPrice && item[this.prop]) {
        const currency = this.getCurrency(i);

        return `${currency} ${item._val || 0}`;
      }

      if (item._type === 'locString') {
        return item._fallback;
      }

      if (item.entry) {
        return this.$t(`${item._code}.val`, {
          p1: this.$tc(`${item._code}.p1`, item.entry.val, { n: item.entry.val })
        });
      }

      if (this.localeConstValues.includes(item._fallback)) {
        return this.$t(item._code);
      }

      return (
        item[this.prop]
        || item._fallback
      );
    },
    hasNote(item) {
      return item.tooltip?._type === 'note';
    },
    hasOrderTooltip(item) {
      return item.tooltip?._type === 'order';
    },
    hasSimpleTooltip(item) {
      return item.tooltip?._type === 'locString';
    },
    hasTooltip(item) {
      return this.hasNote(item) || this.hasOrderTooltip(item) || this.hasSimpleTooltip(item);
    },
    getNoteTooltipValue(item) {
      if (!this.hasNote(item)) {
        return '';
      }

      const {
        body,
        _author: author,
        _editTime: editTime
      } = item.tooltip.note;

      return getNoteTooltip({
        body,
        author,
        editTime
      });
    },
    getTooltipValue(item) {
      if (!this.hasTooltip(item)) {
        return;
      }

      if (this.hasNote(item)) {
        return this.getNoteTooltipValue(item);
      }

      if (this.hasOrderTooltip(item)) {
        const tooltipsArray = toArray(item.tooltip?.orderTooltip?.row, []);
        const tooltips = tooltipsArray.reduce((acc, row) => {
          acc.push(this.$t(row._code, {
            1: row._qty,
            2: dateByLocaleKey(row.date.__text, localeDateKeys.YMD)
          }));

          return acc;
        }, []);

        return {
          html: true,
          content: tooltips.join('<br/>')
        };
      }

      if (this.hasSimpleTooltip(item)) {
        return getTooltip(item.tooltip.fallback);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inventory-table/table-row.scss";
</style>
