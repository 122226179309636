<template>
  <div class="filters__wrapper">
    <ToolbarButton
      :label="$t('Web.Filters.CreateFilter')"
      @click.native="handleNewFilter"
    >
      <icon
        data="@icon/filter.svg"
        class="size-14"
      />
    </ToolbarButton>
    <v-tabs
      :key="tabModel"
      :value="tabModel"
      show-arrows
      center-active
      class="sl-tabs sl-tabs--arrows filter__tabs"
    >
      <v-tab
        class="filters__tab border"
        @click="setTabModel(0)"
      >
        <div class="filters__text">
          {{ $t('Main.All') }}
        </div>
      </v-tab>
      <draggable
        v-model="filtersListModel"
        class="v-item-group filters__dnd"
      >
        <v-tab
          v-for="(item, index) in filtersList"
          :key="item.id"
          v-tooltip="item.name"
          class="filters__tab"
          @click="setTabModel(index + 1)"
          @mousedown.middle="handleFilterDelete(item)"
        >
          <div class="filters__text">
            {{ item.name }}
          </div>
          <icon
            v-if="item.closable"
            data="@icon/close.svg"
            class="size-16 filter__delete"
            @click.stop="handleFilterDelete(item)"
          />
        </v-tab>
      </draggable>
    </v-tabs>
  </div>
</template>

<script>
import { modal } from '@/mixins/modal';
import ToolbarButton from '@/components/Toolbar/ToolbarButton.vue';
import modalsId from '@/config/shared/modalsId.config';
import { namespaceByRoute } from '@/config/report';
import { DEFAULT_FILTER_ID } from '@/config/filter';

export default {
  name: 'FilterTabs',
  components: {
    ToolbarButton
  },
  mixins: [modal],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    filtersList() {
      return this.$store.state[`${this.reportNamespace}`].filters_list;
    },
    activeFilterId() {
      return this.$store.state[`${this.reportNamespace}`].active_filter_id;
    },
    filtersListModel: {
      get() {
        return this.filtersList;
      },
      set(list) {
        this.updateFiltersOrder(list);
      }
    },
    tabModel: {
      get() {
        const index = this.filtersList.findIndex(({ id }) => id === this.activeFilterId);

        return index >= 0 ? index + 1 : 0;
      },
      set(value) {
        if (value === undefined) {
          return;
        }

        const filterId = value > 0
          ? this.filtersListModel[value - 1]?.id ?? DEFAULT_FILTER_ID
          : DEFAULT_FILTER_ID;

        this.setActiveFilterId(filterId);
      }
    }
  },
  methods: {
    setActiveFilterId(...args) {
      this.$store.dispatch(`${this.reportNamespace}/setActiveFilterId`, ...args);
    },
    deleteFilter(...args) {
      this.$store.dispatch(`${this.reportNamespace}/deleteFilter`, ...args);
    },
    updateFiltersOrder(...args) {
      this.$store.dispatch(`${this.reportNamespace}/updateFiltersOrder`, ...args);
    },
    setTabModel(index) {
      if (this.tabModel === index) {
        return;
      }

      this.tabModel = index;
    },
    handleNewFilter() {
      this.showModal(modalsId.REPORT_FILTER);
    },
    handleFilterDelete({ id, name, closable }) {
      if (!closable) {
        return;
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.ActionConfirm.TitleDeleteFilter', { 1: name }),
        text: this.$t('Web.Modals.Delete.Description'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: () => this.deleteFilter(id)
      });
    }
  }
};

</script>

<style>
.filters__dnd {
  display: flex;
}

.filters__wrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  width: 10%;
  max-width: fit-content;
}
</style>
