<template>
  <v-menu
    content-class="sl-menu"
    :disabled="disabled"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="sl-menu-activator"
        data-toolbar-item
        v-on="on"
      >
        <ToolbarButton
          :disabled="disabled"
          @click.native.stop="clickHandler"
        >
          <slot name="icon" />
          <div class="sl-menu-activator__wrapper">
            <span class="sl-menu__label">
              {{ label }}
            </span>
            <icon
              data="@icon/arrow-down.svg"
              class="size-16 color-black menu-button"
              v-on="on"
            />
          </div>
        </ToolbarButton>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :disabled="item.disabled"
        @click="emitEvent(item.value)"
      >
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import ToolbarButton from '@/components/Toolbar/ToolbarButton';

export default {
  name: 'ToolbarMenuButton',
  components: {
    ToolbarButton
  },
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    clickHandler: {
      type: Function,
      required: true
    },
    disabled: Boolean
  },
  methods: {
    emitEvent(key) {
      this.$emit(key);
    }
  }
};
</script>
