
<template>
  <div class="header">
    <div class="inventory-table__row">
      <div class="inventory-table__header" />
      <div
        v-for="(item, i) in data"
        :key="i"
        class="inventory-table__header"
        :data-resize="item._columnPosition"
        :style="{
          'background-color': colorMap[item._background] || '',
          color: colorMap[item._foreground] || '',
          width: `${colSizes[item._columnPosition] || minColSize}px`,
          'margin-left': i === 0 ? pinnedOffsetPx : '0'
        }"
        :class="{
          editable: editableCols.includes(+item._columnPosition),
          'inventory-table__header--double': item.upperHeader || item._useTheSameHeader,
          'inventory-table__header--last-double': isLastDoubleHeader(i),
        }"
      >
        <div
          v-if="isUpperHeaderDisplayed(i)"
          class="inventory-table__header--upper"
          :style="{
            width: calcHeaderWidth(i, item._columnPosition)
          }"
          :data-test-id="`report-tbl-header-upper-${item._class.toLowerCase()}`"
          @click.right.prevent
        >
          {{ getLabel(item.upperHeader) }}
        </div>
        <div
          class="inventory-table__header--lower"
          :class="getLowerHeaderClasses(item)"
          :data-test-id="`report-tbl-header-lower-${item._class.toLowerCase()}`"
          @click="setColSorting(item, i)"
          @click.right.prevent="$emit('context-menu', $event, item)"
        >
          <span>{{ getLabel(item) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortingOrders } from '@/config/report/inventoryReport';
import { getLabel } from '@/helpers/locale/getLabel';
import { namespaceByRoute, DISABLED_STYLE } from '@/config/report';

export default {
  name: 'HeaderRow',
  props: {
    data: {
      type: Array,
      required: true
    },
    prop: {
      type: String,
      required: true
    },
    editableCols: {
      type: Array,
      required: true
    },
    colSizes: {
      type: Array,
      required: true
    },
    minColSize: {
      type: Number,
      required: true
    },
    pinnedOffsetPx: {
      type: String,
      default: '0'
    },
    nested: Boolean
  },
  data() {
    return {
      headerClassesWithParam: [
        'PURCHASE_PRICE',
        'PURCHASE_VALUE',
        'PURCHASE_VALUE_I',
        'BALANCE_PRICE',
        'ORDER_CYCLE',
        'SHELF_LIFE',
        'ITEM_CAT_I',
        'ITEMCAT_I'
      ],
      colorMap: {
        gree: 'rgba(169,255,169,.5)',
        grer: 'rgba(130, 200, 140, 0.7)',
        veau: '#888888',
        'red-': 'rgba(255,169,169,.5)',
        high: '#cccccc66',
        link: '#006eb7'
      },
      getLabel
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    resizerWrapperSelector() {
      return `.inventory-table${this.nested ? '--nested' : ''}`;
    },
    sortParams() {
      return this.$store.getters[`${this.reportNamespace}/sortParams`];
    },
    isItemAscSorted() {
      return this.sortParams?.order === sortingOrders.ASCENDING;
    }
  },
  methods: {
    getLowerHeaderClasses(item) {
      return {
        sort: this.isItemSorted(item._columnPosition),
        'sort--asc': this.isItemAscSorted,
        'sort--desc': !this.isItemAscSorted,
        'inventory-table__header--disabled': this.isHeaderDisabled(item)
      };
    },
    setSortingParams(...args) {
      this.$store.dispatch(`${this.reportNamespace}/setSortingParams`, ...args);
    },
    setColSorting(item, i) {
      const newSortingOrder = {
        class: item._class
      };

      if (this.sortParams.class !== item._class) {
        newSortingOrder.order = sortingOrders.ASCENDING;
      } else {
        newSortingOrder.order = this.isItemAscSorted ? sortingOrders.DESCENDING : sortingOrders.ASCENDING;
      }

      this.setSortingParams({
        sortOrder: newSortingOrder.order,
        itemClass: newSortingOrder.class,
        globalIndex: i,
        index: item._index
      });
    },
    getUpperHeaderByIndex(colIndex) {
      return this.data?.[colIndex]?.upperHeader;
    },
    isItemSorted(i) {
      return +this.sortParams?.globalIndex === +i;
    },
    isHeaderDisabled(item) {
      return item._style === DISABLED_STYLE;
    },
    isLastDoubleHeader(colIndex) {
      const upperHeader = this.getUpperHeaderByIndex(colIndex);

      if (!upperHeader) {
        return false;
      }

      const nextUpperHeader = this.getUpperHeaderByIndex(colIndex + 1);

      return upperHeader._class !== nextUpperHeader?._class || upperHeader._index !== nextUpperHeader?._index;
    },
    isUpperHeaderDisplayed(colIndex) {
      const upperHeader = this.getUpperHeaderByIndex(colIndex);

      if (!upperHeader) {
        return false;
      }

      const prevColIndex = colIndex - 1 < 0 ? 0 : colIndex - 1;

      return !prevColIndex || !this.isSameUpperHeader(colIndex, prevColIndex);
    },
    isSameUpperHeader(i, j) {
      const headerExists = this.data[j];

      if (!headerExists) {
        return false;
      }

      const currentHeader = this.data[i].upperHeader;
      const nextHeader = this.data[j].upperHeader;

      const isSameUpperHeaderClass = currentHeader?._class === nextHeader?._class;
      const isSameUpperHeaderDate = currentHeader?.date?.__text === nextHeader?.date?.__text;
      const isSameUpperHeaderLabel = currentHeader?.__text === nextHeader?.__text;

      return isSameUpperHeaderClass && isSameUpperHeaderDate && isSameUpperHeaderLabel;
    },
    calcHeaderWidth(startIndex, dataResizeIndex) {
      let i = startIndex;
      let endIndex = startIndex + 1;
      let upperHeaderWidth = 0;

      while (this.isSameUpperHeader(i, i + 1)) {
        i++;
      }

      endIndex = i;

      let header = this.getHeaderItemByDataIndex(dataResizeIndex);

      for (let j = startIndex; j <= endIndex; j++) {
        const width = header?.style?.width || `${this.minColSize}`;

        upperHeaderWidth += parseInt(width, 10);

        header = header?.nextSibling;
      }

      return upperHeaderWidth - 1 + 'px';
    },
    getHeaderItemByDataIndex(index) {
      return document.querySelector(this.resizerWrapperSelector)?.querySelector(`[data-resize="${index}"]`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inventory-table/header-row.scss";
</style>
