import { namespaceByRoute, PAGE_SIZE } from '@/config/report';

export const pagination = {
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    queryPage() {
      return this.$store.state[`${this.reportNamespace}`]?.query?.page || 1;
    },
    maxPages() {
      return Math.ceil(this.totalRowsCount / PAGE_SIZE);
    },
    startIndex() {
      return (this.queryPage - 1) * PAGE_SIZE;
    }
  },
  beforeMount() {
    const page = this.$route.query.page;

    if (!page && this.totalRowsCount !== null) {
      return this.updatePageRoute(this.queryPage);
    }
  },
  watch: {
    totalRowsCount(val) {
      if (val !== null) {
        this.updatePageRoute(this.queryPage);
      }
    }
  },
  methods: {
    setQueryParams(params) {
      return this.$store.dispatch(`${this.reportNamespace}/setQueryParams`, params);
    },
    getPageNum(page) {
      return this.maxPages >= +page ? +page : 1;
    },
    updatePageRoute(page) {
      const pageNum = this.getPageNum(page);
      const searchQuery = this.$route.query.search;

      this.setQueryParams({ page: pageNum });

      return this.$router.push({
        path: this.$route.path,
        query: {
          ...(pageNum && { page: pageNum }),
          ...(searchQuery && { search: searchQuery })
        }
      });
    }
  }
};