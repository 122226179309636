<template>
  <div class="inventory-skeleton">
    <ToolbarSkeleton :color="'grey-darker'" />
    <ToolbarSkeleton />
    <div class="inventory-skeleton__table">
      <v-skeleton-loader
        class="inventory-skeleton__table-thead"
        type="table-thead"
        :types="{
          'table-thead': 'heading@10'
        }"
      />
      <v-skeleton-loader
        type="table-row-divider@25"
        :types="{
          'table-row': 'table-cell@10'
        }"
      />
    </div>
  </div>
</template>

<script>
import ToolbarSkeleton from '@/components/Skeleton/ToolbarSkeleton.vue';

export default {
  name: 'InventorySkeleton',
  components: {
    ToolbarSkeleton
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/inventory-skeleton.scss";
</style>
