var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ReportToolbar',{staticClass:"reports-toolbar",attrs:{"show-hide-items":_vm.showHideItems},on:{"on-search":function($event){return _vm.$emit('on-search')}}},[_vm._l((_vm.toolbarData),function(control){return _c('div',{key:control.requestKey,staticClass:"report-toolbar__option",attrs:{"data-toolbar-item":""}},[(control.type === _vm.controlType.checkbox)?_c('v-checkbox',{staticClass:"sl-checkbox",attrs:{"id":control.requestKey,"disabled":!control.enabled},on:{"change":function($event){return _vm.setCheckbox(control)}},model:{value:(control.val),callback:function ($$v) {_vm.$set(control, "val", $$v)},expression:"control.val"}}):_vm._e(),(_vm.isLabel(control))?_c('label',{staticClass:"report-toolbar__title",attrs:{"for":control.requestKey}},[_vm._v(" "+_vm._s(control.name)+" ")]):_vm._e(),(control.type === _vm.controlType.numberInput)?_c('SlNumberInput',{staticClass:"sl-number-input--white",attrs:{"id":control.requestKey,"value":+control.val,"disabled":!control.enabled,"min":+control.min,"max":+control.max,"step":1},on:{"input":function (val) {
            var _obj;

            return _vm.setControl(( _obj = {}, _obj[control.requestKey] = +val, _obj ));
}},model:{value:(control.val),callback:function ($$v) {_vm.$set(control, "val", $$v)},expression:"control.val"}}):(control.type === _vm.controlType.select)?_c('v-select',{staticClass:"sl-select sl-select--white",attrs:{"items":_vm.getItems(control.selection),"value":_vm.getValue(control.val, control.selection),"item-text":"label","dense":"","solo":""},on:{"change":function (val) { return _vm.changeSelect(val, control); }}}):(control.type === _vm.controlType.comboBox)?_c('v-menu',{attrs:{"content-class":"sl-menu col-visibility-menu aggregation","close-on-content-click":false,"max-height":"auto","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"sl-menu-activator aggregation__title"},'div',attrs,false),on),[_c('div',{staticClass:"aggregation__label"},[_vm._v(" "+_vm._s(_vm.getAggregateLabel(control.selection))+" ")]),_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((control.selection),function(item,index){return _c('v-list-item',{key:index,staticClass:"aggregation__option"},[_c('v-checkbox',{staticClass:"sl-checkbox aggregation__checkbox",class:{
              'sl-checkbox--is-hidden': item.noCheckbox
            },attrs:{"id":item.value},on:{"change":function($event){
            var _obj;
return _vm.setControl(( _obj = {}, _obj[control.requestKey] = item.value, _obj ))}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}),_c('label',{staticClass:"aggregation__label",attrs:{"for":item.value}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1)],1)}),1)],1):_vm._e(),(control.type === _vm.controlType.button)?_c('ToolbarButton',{attrs:{"disabled":!control.enabled},nativeOn:{"click":function($event){return _vm.handleImportChanges($event)}}},[_c('icon',{staticClass:"size-18",attrs:{"data":require("@icon/import.svg")}}),_c('div',{staticClass:"toolbar-button__label"},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.acImportListFromXlsx'))+" ")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"uploader",refInFor:true,attrs:{"type":"file","accept":_vm.xlsxAccepted},on:{"change":_vm.onFileChanged}})],1):_vm._e()],1)}),_c('ToolbarMenuButton',{attrs:{"label":_vm.$t('Main.Ui.acExportForecast'),"items":_vm.exportTableItems,"click-handler":function () { return _vm.handleExportToXlsx(false); }},on:_vm._d({},[_vm.exportTableKeys.DB,_vm.handleExportToDatabase,_vm.exportTableKeys.XLSX,function($event){return _vm.handleExportToXlsx(false)},_vm.exportTableKeys.XLSX_IN_TRANS_FORMAT,function($event){return _vm.handleExportToXlsx(true)}]),scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('icon',{staticClass:"size-18",attrs:{"data":require("@icon/export.svg")}})]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }