<template>
  <SlContextMenu
    ref="contextMenu"
    :element-id="menuElementId"
    class="header-context-menu"
    :options="headerContextMenuOptions"
    @option-clicked="handleOptionClick"
  >
    <template #[contextMenuKeys.PIN_COLUMN]="{ handler, name }">
      <OptionItem
        :handler="handler"
        :disabled="isPinDisabled"
      >
        {{ name(isPinned) }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.HIDE_COLUMN]="{ handler, name }">
      <OptionItem
        :handler="handler"
        :disabled="isHideColDisabled"
      >
        {{ name }}
      </OptionItem>
    </template>
  </SlContextMenu>
</template>

<script>
import SlContextMenu from '@/components/Shared/SlContextMenu/Index.vue';
import OptionItem from '@/components/Shared/SlContextMenu/OptionItem.vue';
import {
  contextMenuKeys,
  inventoryHeaderContextActionsMap,
  inventoryHeaderContextMenuOptions
} from '@/config/shared/contextMenu.config';
import { MAX_PINNED_COLUMNS, namespaceByRoute } from '@/config/report';

export default {
  name: 'HeaderContextMenu',
  components: {
    SlContextMenu,
    OptionItem
  },
  props: {
    headerClass: {
      type: String,
      required: true
    },
    headerIndex: {
      type: String,
      default: undefined
    },
    nested: Boolean
  },
  data() {
    return {
      contextMenuKeys
    };
  },
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    pinnedColumns() {
      return this.$store.state[`${this.reportNamespace}`]?.pinnedColumns;
    },
    isPinned() {
      return this.pinnedColumns.find(col => col.class === this.headerClass && col.index === this.headerIndex);
    },
    isPinDisabled() {
      if (this.isPinned) {
        return false;
      }

      return this.pinnedColumns.length >= MAX_PINNED_COLUMNS;
    },
    menuElementId() {
      return `header-context-menu${this.nested ? '--nested' : ''}`;
    },
    colVisibilityItems() {
      return this.$store.state[this.reportNamespace]?.colVisibilityItems || [];
    },
    headerContextMenuOptions() {
      return inventoryHeaderContextMenuOptions(this);
    },
    currentCol() {
      return this.findCurrentCol(this.colVisibilityItems);
    },
    isHideColDisabled() {
      return this.currentCol?.disabled;
    }
  },
  methods: {
    findCurrentCol(arr) {
      for (let i = 0; i <= arr.length - 1; i++) {
        const { _class, _index, sublist } =  arr[i] || {};

        if (_class && _class.startsWith('DEBT')) {
          return arr[i];
        }

        if (_class === this.headerClass) {
          if (_index === this.headerIndex || _index === undefined) {
            return arr[i];
          }
        }

        if (sublist) {
          const itemClass = this.findCurrentCol(sublist);

          if (itemClass) {
            return itemClass;
          }
        }
      }
    },
    handleOptionClick(event) {
      if (event?.option?.key) {
        const action = inventoryHeaderContextActionsMap[event.option.key];

        if (this[action]) {
          this[action]();
        }
      }
    },
    getPayload(item) {
      if (!item) {
        return;
      }

      const payload = {
        class: item._class,
        state: !(+item._visible)
      };

      if (item._index) {
        payload.index = item._index;
      }

      return payload;
    },
    handleColVisibilityChange(...args) {
      this.$store.dispatch(`${this.reportNamespace}/updateVisibleCols`, ...args);
    },
    handleHideColumn() {
      this.handleColVisibilityChange(this.getPayload(this.currentCol));
    },
    handleToggleColumnPin() {
      const payload = {
        class: this.headerClass,
        index: this.headerIndex
      };

      this.$store.dispatch(`${this.reportNamespace}/toggleColumnPin`, {
        col: payload,
        unpin: this.isPinned
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inventory-table/header-context-menu.scss";
</style>
