var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"inventory-table__row"},[_c('div',{staticClass:"inventory-table__header"}),_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"inventory-table__header",class:{
        editable: _vm.editableCols.includes(+item._columnPosition),
        'inventory-table__header--double': item.upperHeader || item._useTheSameHeader,
        'inventory-table__header--last-double': _vm.isLastDoubleHeader(i),
      },style:({
        'background-color': _vm.colorMap[item._background] || '',
        color: _vm.colorMap[item._foreground] || '',
        width: ((_vm.colSizes[item._columnPosition] || _vm.minColSize) + "px"),
        'margin-left': i === 0 ? _vm.pinnedOffsetPx : '0'
      }),attrs:{"data-resize":item._columnPosition}},[(_vm.isUpperHeaderDisplayed(i))?_c('div',{staticClass:"inventory-table__header--upper",style:({
          width: _vm.calcHeaderWidth(i, item._columnPosition)
        }),attrs:{"data-test-id":("report-tbl-header-upper-" + (item._class.toLowerCase()))},on:{"contextmenu":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.getLabel(item.upperHeader))+" ")]):_vm._e(),_c('div',{staticClass:"inventory-table__header--lower",class:_vm.getLowerHeaderClasses(item),attrs:{"data-test-id":("report-tbl-header-lower-" + (item._class.toLowerCase()))},on:{"click":function($event){return _vm.setColSorting(item, i)},"contextmenu":function($event){$event.preventDefault();return _vm.$emit('context-menu', $event, item)}}},[_c('span',[_vm._v(_vm._s(_vm.getLabel(item)))])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }