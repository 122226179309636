export default {
  data() {
    return {
      search: this.$route.query.search || ''
    };
  },
  mounted() {
    if (this.search) {
      this.searchItems();

      this.$nextTick(() => {
        this.$refs.search.$refs.input.focus();
      });
    }
  },
  methods: {
    setQueryParams(params) {
      return this.$store.dispatch(`${this.reportNamespace}/setQueryParams`, params);
    },
    async searchItems() {
      await this.setQueryParams({ page: 1 });

      await this.$router.push({
        path: this.$route.path,
        query: {
          search: this.search || undefined
        }
      });

      this.$emit('on-search');
    }
  }
};
