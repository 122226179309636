var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inventory-table__col"},_vm._l((_vm.data),function(item,i){
var _obj;
return _c('div',{key:i,ref:("cell-" + i),refInFor:true,staticClass:"inventory-table__cell inventory-table__cell--editable inventory-table__cell--dropdown",class:( _obj = {}, _obj[("background--" + (item._background))] = !_vm.resetSelects && item._background, _obj[("color--" + (item._foreground))] = !_vm.resetSelects && item._foreground, _obj['background--darken'] =  _vm.isItemSelected(i), _obj ),attrs:{"tabindex":"-1"},on:{"click":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function () { return _vm.handleCellFocus(i, $event); })($event)},function($event){if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.$emit('select-ctrl-click', i)},function($event){if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }return _vm.$emit('select-shift-click', i)}],"blur":_vm.handleCellBlur,"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.handleSelectMousedown(i)},"mouseenter":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.$emit('select-mouseenter', i)},"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('context-menu', $event, i)}}},[(_vm.isDC)?[(_vm.isFocusedCell(i) && !_vm.isDisabled(item, i))?_c('v-select',{ref:("select-" + i),refInFor:true,staticClass:"sl-table-select sl-table-select--inventory",attrs:{"items":_vm.getDCOptions(i),"label":item._val,"menu-props":{
          contentClass: 'sl-select__menu',
          closeOnContentClick: true,
          auto: true
        },"solo":""},on:{"change":function (val) { return _vm.handleSelectChange(val, i); }}}):[_vm._v(" "+_vm._s(item._val)+" ")]]:(_vm.isGroups)?[(_vm.isFocusedCell(i) && !_vm.supportsGroups(item, i))?_c('v-select',{ref:("select-" + i),refInFor:true,staticClass:"sl-table-select sl-table-select--inventory",attrs:{"items":_vm.getSelectItems(_vm.containerTypes.GROUP),"label":item._val,"menu-props":{
          contentClass: 'sl-select__menu',
          closeOnContentClick: true,
          auto: true
        },"solo":"","return-object":""},on:{"change":function (val) { return _vm.handleSelectChange(val, i); }}}):[_vm._v(" "+_vm._s(item._val)+" ")]]:(_vm.isManufactureGroup)?[(_vm.isFocusedCell(i) && !_vm.supportsManufactureGroups(item, i))?_c('v-select',{ref:("select-" + i),refInFor:true,staticClass:"sl-table-select sl-table-select--inventory",attrs:{"items":_vm.getSelectItems(_vm.containerTypes.MANUFACTURE_GROUP),"label":item._val,"menu-props":{
          contentClass: 'sl-select__menu',
          closeOnContentClick: true,
          auto: true
        },"solo":"","return-object":""},on:{"change":function (val) { return _vm.handleSelectChange(val, i); }}}):[_vm._v(" "+_vm._s(item._val)+" ")]]:(_vm.isContainer)?[(_vm.isFocusedCell(i) && !_vm.supportsContainers(item, i))?_c('v-select',{ref:("select-" + i),refInFor:true,staticClass:"sl-table-select sl-table-select--inventory",attrs:{"items":_vm.getSelectItems(_vm.containerTypes.CONTAINER),"label":item._val,"menu-props":{
          contentClass: 'sl-select__menu',
          closeOnContentClick: true,
          auto: true
        },"solo":"","return-object":""},on:{"change":function (val) { return _vm.handleSelectChange(val, i); }}}):[_vm._v(" "+_vm._s(item._val)+" ")]]:(_vm.isOrderingDays)?[(_vm.isFocusedCell(i))?_c('v-select',{ref:("select-" + i),refInFor:true,staticClass:"sl-table-select sl-table-select--inventory",attrs:{"label":item._val,"items":_vm.orderingDaysOptions,"item-text":"name","menu-props":{
          contentClass: 'sl-select__menu sl-multiple-select__menu ordering-days',
          auto: true
        },"solo":"","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{staticClass:"color--over inventory-table__cell--dropdown-option",on:{"mousedown":_vm.clearOrderingDaysOverrides}},[_vm._v(" "+_vm._s(_vm.$t('Main.CheckboxClearOv'))+" ")])]},proxy:true}],null,true),model:{value:(_vm.selectedOrderingDays),callback:function ($$v) {_vm.selectedOrderingDays=$$v},expression:"selectedOrderingDays"}}):[_vm._v(" "+_vm._s(item._val)+" ")]]:(_vm.isSupplierCol)?[(_vm.isFocusedCell(i))?_c('v-select',{ref:("select-" + i),refInFor:true,staticClass:"sl-table-select sl-table-select--inventory",attrs:{"label":item._val,"items":_vm.supplierOptions,"item-text":"name","menu-props":{
          contentClass: 'sl-select__menu',
          closeOnContentClick: true,
          auto: true
        },"solo":"","return-object":""},on:{"change":function (val) { return _vm.handleSelectChange(val, i); }}}):[_vm._v(" "+_vm._s(item._val)+" ")]]:_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }