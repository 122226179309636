<template>
  <input
    type="number"
    :value="parsedValue"
    :min="min"
    :max="max"
    :step="step"
    :disabled="disabled"
    class="sl-number-input"
    @input="formatInputNumber"
    @change="changeInputNumber"
    @blur="handleEmptyInput"
  >
</template>

<script>
import { formatMinMax } from '@/helpers/format/formatMinMax.js';

export default {
  name: 'SlNumberInput',
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    },
    step: {
      type: Number,
      default: 1
    },
    fixed: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      number: `${this.parsedValue}`
    };
  },
  computed: {
    parsedValue() {
      return `${this.value}`.replace(/,/, '.');
    }
  },
  methods: {
    formatInputNumber({ target }) {
      if (target.value === '') {
        this.number = '';
      }

      if (
        target.value
        && target.value !== this.number
        && !(target.value.length < this.number.length && this.number.includes('.'))
      ) {

        if (this.fixed >= 0 && target.value.split('.')[1]?.length > this.fixed) {
          target.value = (+target.value).toFixed(this.fixed);
        }

        target.value = formatMinMax(
          target.value,
          this.min,
          this.max
        );

        this.number = target.value;

        this.submitNumberInput(target.value);
      }
    },
    handleEmptyInput({ target }) {
      this.number = '';

      if (target.value === '') {
        target.value = this.parsedValue;

        this.submitNumberInput(target.value);
      }
    },
    submitNumberInput(value) {
      this.$emit('input', value);
    },
    changeInputNumber({ target }) {
      const value = formatMinMax(
        target.value,
        this.min,
        this.max
      );

      this.submitNumberInput((+value).toFixed(this.fixed));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_variable.scss";
.sl-number-input {
  min-width: 50px;
  height: $height-input;
  margin-right: -1px;
  padding: 1px 0 1px 5px;
  background: white;
  border: 1px solid $color-grey-darker;
  border-radius: 3px;
  outline: none;
  -moz-appearance: auto;

  &:disabled {
    opacity: $opacity-input-disabled;
  }

  &.sl-number-input--grey {
    background: $color-input-grey;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: auto;
  }
}
</style>
