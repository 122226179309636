<template>
  <div class="inventory-table__col">
    <div
      v-for="(item, i) in data"
      :key="i"
      :ref="`cell-${i}`"
      tabindex="-1"
      class="inventory-table__cell inventory-table__cell--editable inventory-table__cell--dropdown"
      :class="{
        [`background--${item._background}`]: !resetSelects && item._background,
        [`color--${item._foreground}`]: !resetSelects && item._foreground,
        'background--darken': isItemSelected(i)
      }"
      @click.left.exact="() => handleCellFocus(i, $event)"
      @blur="handleCellBlur"
      @mousedown.left.exact="handleSelectMousedown(i)"
      @mouseenter.exact="$emit('select-mouseenter', i)"
      @click.ctrl.exact="$emit('select-ctrl-click', i)"
      @click.shift.exact="$emit('select-shift-click', i)"
      @click.right.prevent.stop="$emit('context-menu', $event, i)"
    >
      <template v-if="isDC">
        <v-select
          v-if="isFocusedCell(i) && !isDisabled(item, i)"
          :ref="`select-${i}`"
          class="sl-table-select sl-table-select--inventory"
          :items="getDCOptions(i)"
          :label="item._val"
          :menu-props="{
            contentClass: 'sl-select__menu',
            closeOnContentClick: true,
            auto: true
          }"
          solo
          @change="(val) => handleSelectChange(val, i)"
        />
        <template v-else>
          {{ item._val }}
        </template>
      </template>
      <template v-else-if="isGroups">
        <v-select
          v-if="isFocusedCell(i) && !supportsGroups(item, i)"
          :ref="`select-${i}`"
          class="sl-table-select sl-table-select--inventory"
          :items="getSelectItems(containerTypes.GROUP)"
          :label="item._val"
          :menu-props="{
            contentClass: 'sl-select__menu',
            closeOnContentClick: true,
            auto: true
          }"
          solo
          return-object
          @change="(val) => handleSelectChange(val, i)"
        />
        <template v-else>
          {{ item._val }}
        </template>
      </template>
      <template v-else-if="isManufactureGroup">
        <v-select
          v-if="isFocusedCell(i) && !supportsManufactureGroups(item, i)"
          :ref="`select-${i}`"
          class="sl-table-select sl-table-select--inventory"
          :items="getSelectItems(containerTypes.MANUFACTURE_GROUP)"
          :label="item._val"
          :menu-props="{
            contentClass: 'sl-select__menu',
            closeOnContentClick: true,
            auto: true
          }"
          solo
          return-object
          @change="(val) => handleSelectChange(val, i)"
        />
        <template v-else>
          {{ item._val }}
        </template>
      </template>
      <template v-else-if="isContainer">
        <v-select
          v-if="isFocusedCell(i) && !supportsContainers(item, i)"
          :ref="`select-${i}`"
          class="sl-table-select sl-table-select--inventory"
          :items="getSelectItems(containerTypes.CONTAINER)"
          :label="item._val"
          :menu-props="{
            contentClass: 'sl-select__menu',
            closeOnContentClick: true,
            auto: true
          }"
          solo
          return-object
          @change="(val) => handleSelectChange(val, i)"
        />
        <template v-else>
          {{ item._val }}
        </template>
      </template>
      <template v-else-if="isOrderingDays">
        <v-select
          v-if="isFocusedCell(i)"
          :ref="`select-${i}`"
          v-model="selectedOrderingDays"
          class="sl-table-select sl-table-select--inventory"
          :label="item._val"
          :items="orderingDaysOptions"
          item-text="name"
          :menu-props="{
            contentClass: 'sl-select__menu sl-multiple-select__menu ordering-days',
            auto: true
          }"
          solo
          multiple
          return-object
        >
          <template #append-item>
            <div
              class="color--over inventory-table__cell--dropdown-option"
              @mousedown="clearOrderingDaysOverrides"
            >
              {{ $t('Main.CheckboxClearOv') }}
            </div>
          </template>
        </v-select>
        <template v-else>
          {{ item._val }}
        </template>
      </template>
      <template v-else-if="isSupplierCol">
        <v-select
          v-if="isFocusedCell(i)"
          :ref="`select-${i}`"
          class="sl-table-select sl-table-select--inventory"
          :label="item._val"
          :items="supplierOptions"
          item-text="name"
          :menu-props="{
            contentClass: 'sl-select__menu',
            closeOnContentClick: true,
            auto: true
          }"
          solo
          return-object
          @change="(val) => handleSelectChange(val, i)"
        />
        <template v-else>
          {{ item._val }}
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import { inventoryCellFgs as fgs } from '@/config/shared/fgs.config';
import modalsId from '@/config/shared/modalsId.config';
import { tableColsClasses } from '@/config/report/inventoryReport';
import { containerTypes } from '@/config/report/inventoryReport/collections.config';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'InventoryTableDDColumn',
  mixins: [access, modal],
  props: {
    data: {
      type: Array,
      required: true
    },
    colIndex: {
      type: Number,
      required: true
    },
    dropdownCols: {
      type: Object,
      required: true
    },
    dropdowns: {
      type: Object,
      required: true
    },
    selectedRows: {
      type: Array,
      required: true
    },
    rowsInfo: {
      type: Array,
      required: false,
      default: () => ([])
    },
    isSupplier: Boolean,
    isOrderingDays: Boolean,
    isEditable: Boolean
  },
  data() {
    return {
      containerTypes,
      supplierOptions: [],
      orderingDaysOptions: [],
      selectedOrderingDays: [],
      resetSelects: false,
      wantToClear: false,
      focusedCellIndex: null,
      lastActiveItem: null,
      lastRowIndex: null,
      labelByKey: {
        [containerTypes.GROUP]: this.$t('CreateGroup.comboItemCreateGroup'),
        [containerTypes.CONTAINER]: this.$t('CreateContainer.comboItemCreateContainer'),
        [containerTypes.MANUFACTURE_GROUP]: this.$t('CreateGroup.comboItemCreateGroup')
      }
    };
  },
  inject: [
    'locationByIndex',
    'itemByIndex'
  ],
  computed: {
    ...mapGetters({
      getExistingIds: 'inventoryReport/collections/getExistingIds',
      getCreateData: 'inventoryReport/collections/getCreateData'
    }),
    dataJSON() {
      return JSON.stringify(this.data);
    },
    isDC() {
      return !this.resetSelects && this.checkColIndex(this.dropdownCols[tableColsClasses.DISTRIB_CENTER]);
    },
    isGroups() {
      return !this.resetSelects && this.checkColIndex(this.dropdownCols[tableColsClasses.GROUP]);
    },
    isManufactureGroup() {
      return !this.resetSelects && this.checkColIndex(this.dropdownCols[tableColsClasses.BOM_GROUP]);
    },
    isContainer() {
      return !this.resetSelects && this.checkColIndex(this.dropdownCols[tableColsClasses.CONTAINER]);
    },
    isSupplierCol() {
      return !this.resetSelects && this.isSupplier;
    }
  },
  watch: {
    dataJSON() {
      this.resetSelects = true;

      this.$nextTick(() => {
        this.resetSelects = false;
      });
    }
  },
  methods: {
    ...mapActions({
      fetchOrderingDays: 'inventoryReport/fetchOrderingDays',
      fetchSupplierNames: 'suppliers/fetchSupplierNames',
      createEntityAndSave: 'inventoryReport/collections/createEntityAndSave',
      fetchDCOptions: 'inventoryReport/fetchDCs'
    }),
    async getOrderingDays(rowIndex) {
      const item = this.itemByIndex(rowIndex);
      const location = this.locationByIndex(rowIndex);

      const res = await this.fetchOrderingDays({
        item: item?._val || '',
        location: location?._val || ''
      });

      this.orderingDaysOptions = res;

      this.selectedOrderingDays = res.filter(option => option.isChecked);
    },
    async getSuppliers(rowIndex) {
      const item = this.itemByIndex(rowIndex);

      if (item?._val === this.lastActiveItem?._val) {
        return;
      }

      const res = await this.fetchSupplierNames({
        item: item?._val || ''
      });

      this.supplierOptions = res || [];

      if (this.supplierOptions.length) {
        this.supplierOptions.unshift('');
      }

      this.lastActiveItem = item;
    },
    async getDC(rowIndex) {
      if (this.lastRowIndex === rowIndex) {
        return;
      }

      const item = this.itemByIndex(rowIndex);

      return await this.fetchDCOptions({
        item: item?._val || ''
      });
    },
    getSelectItems(key) {
      return [
        { text: '', value: '' },
        ...toArray(this.dropdowns[key]),
        { text: this.labelByKey[key], value: key }
      ];
    },
    getDCOptions(index) {
      if (!this.dropdowns?.dc) {
        return [];
      }

      const location = this.locationByIndex(index);
      const locationValue = location?._val;

      const filteredOptions = [
        '',
        ...this.dropdowns.dc.filter((el) => el !== locationValue)
      ];

      return filteredOptions.length > 1 ? filteredOptions : [];
    },
    handleCellFocus(index, event) {
      if (this.isSupplier && !this.isEditable) {
        event.preventDefault();

        return;
      }

      this.focusedCellIndex = index;

      if (this.isOrderingDays) {
        this.getOrderingDays(index);
      } else if (this.isSupplier) {
        this.getSuppliers(index);
      } else if (this.isDC) {
        this.getDC(index);
      }

      this.lastRowIndex = index;

      this.$nextTick(() => {
        const select = this.$refs[`select-${index}`];

        if (select && !select[0].isFocused) {
          this.toggleTableScroll(false);
          select[0].onClick(event);
        }
      });
    },
    clearOrderingDaysOverrides() {
      this.wantToClear = true;
      this.selectedOrderingDays = [];
    },
    handleCellBlur() {
      this.$nextTick(() => {
        if (this.isOrderingDays) {
          if (!this.wantToClear && !this.selectedOrderingDays.length) {
            this.selectedOrderingDays = [{ id: '' }];
          }

          this.handleUpdateRow(
            this.selectedOrderingDays.map(item => item.id).join(','),
            this.focusedCellIndex
          );

          this.wantToClear = false;
        }

        this.orderingDaysOptions = [];
        this.selectedOrderingDays = [];

        this.focusedCellIndex = null;
        this.toggleTableScroll(true);
      });
    },
    toggleTableScroll(canScroll) {
      const rootTable = document.querySelector('.inventory-table .inventory-table__scroller');

      if (!rootTable) {
        return;
      }

      rootTable.classList.toggle('scroll-block', !canScroll);
    },
    checkColIndex(colIndex) {
      return colIndex === this.colIndex;
    },
    handleSelectMousedown(index) {
      this.$emit('cell-select', index);
    },
    handleSelectChange(selectItem, index) {
      if (Object.values(containerTypes).includes(selectItem.value)) {
        return this.showModal(modalsId.CREATE_COLLECTION, {
          inputData: this.getCreateData(selectItem.value, this),
          existingIds: this.getExistingIds(selectItem.value),
          type: selectItem.value,
          createCallback: (item) => this.handleCreateEntity(item, selectItem.value, index)
        });
      }

      const value = selectItem.value ?? selectItem;

      this.handleUpdateRow(value, index);
      this.toggleTableScroll(true);
    },
    async handleCreateEntity(item, type, index) {
      await this.createEntityAndSave({
        type,
        ...item
      });

      this.handleUpdateRow(item.identifier, index);
    },
    handleUpdateRow(value, index) {
      this.$emit('row-update', {
        value,
        cellIndex: index
      });

      if (!value) {
        setTimeout(() => {
          this.resetSelects = true;

          this.$nextTick(() => {
            this.resetSelects = false;
          });
        }, 100);
      }

      this.selectedOrderingDays = [];
    },
    isFocusedCell(i) {
      return this.focusedCellIndex === i;
    },
    isItemSelected(index) {
      return this.selectedRows.includes(index);
    },
    supportsContainers(item, index) {
      return this.isDisabled(item, index) || !(+item._fgs & fgs.SUPPORTS_CONTAINERS);
    },
    supportsGroups(item, index) {
      return this.isDisabled(item, index) || !(+item._fgs & fgs.SUPPORTS_GROUPS);
    },
    supportsManufactureGroups(item, index) {
      return this.isDisabled(item, index) || !(+item._fgs & fgs.SUPPORTS_BOM_GROUPS);
    },
    isDisabled(item, index) {
      return !this.$sl_tabEditable || !(+item._fgs & fgs.EDITABLE) || this.rowsInfo[index]?.editable === '0';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inventory-table/table-row.scss";
</style>
